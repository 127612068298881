/* eslint-disable react/no-array-index-key */
import { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Input,
} from "reactstrap";

interface Props {
  id: string;
  handleChange: (x: string) => void;
  data: string[];
  initActive?: string;
  value: string;
}

const SelectDropdown = ({
  id,
  data,
  handleChange,
  value,
  initActive = "",
}: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [active, setActive] = useState<string>("");

  useEffect(() => {
    setActive(value);
  }, [value]);

  const handleSelectItem = (item: string) => {
    setActive(item);
    handleChange(item);
  };

  const renderItem = (item: any) => {
    return (
      <DropdownItem key={item} onClick={() => handleSelectItem(item)}>
        {item}
      </DropdownItem>
    );
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      style={{
        height: "56px",
      }}
    >
      <DropdownToggleStyle
        caret
        color="x"
        className="p-0 border-0 d-flex justify-content-between align-items-center"
        onKeyDown={(e: any) => {
          if (e && e.code === "Space") {
            e.preventDefault();
            setActive((active) => active + " ");
          }
          if (e && e.code === "Enter") {
            document.getElementById(id).blur();
            toggle();
          }
        }}
        onClick={() => {
          if (dropdownOpen) document.getElementById(id).blur();
        }}
      >
        <Input
          id={id}
          onChange={(e) => {
            handleChange(e.target.value);
            setActive(e.target.value);
          }}
          placeholder={initActive}
          value={active}
          onBlur={(e) => {
            handleChange(e.target.value.trim());
            setActive(e.target.value.trim());
          }}
          style={{
            border: "thin solid",
            height: "56px",
            borderRadius: "8px",
            background: "#fff",
          }}
        />
      </DropdownToggleStyle>
      <DropdownMenu className="mt-2">
        {data
          .filter((el) => el.toLowerCase().includes(active.toLowerCase()))
          .map((item) => renderItem(item))}
      </DropdownMenu>
    </Dropdown>
  );
};

export const DropdownToggleStyle = styled(DropdownToggle)`
  &.btn {
    height: 100%;
    width: 100%;
    color: #263238;
    font-size: 16px;
    font-weight: 400;
    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
    }
  }

  &.btn::after {
    position: absolute;
    margin-left: calc(100% - 25px) !important;
  }
`;

export default SelectDropdown;
