import axios from "axios";

import {
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGOUT_FAIL,
} from "./types";
import setAuthToken from "../helpers/setAuthToken";
import { BaseURL } from "../constant";
import jwt_decode from "jwt-decode";
import { TOKEN_NAME } from "../constant";

export const loadUser = () => async (dispatch) => {
  const token = localStorage.getItem(TOKEN_NAME);

  if (!token) {
    dispatch({
      type: LOAD_USER_FAIL,
    });
  }

  try {
    const decodedToken = jwt_decode(token);

    if (!decodedToken.id) {
      dispatch({
        type: LOAD_USER_FAIL,
      });
    }

    setAuthToken(token);
    const res = await axios.get(BaseURL + "/users/" + decodedToken.id);
    dispatch({
      type: LOAD_USER_SUCCESS,
      payload: res?.data,
    });
  } catch (err) {
    dispatch({
      type: LOAD_USER_FAIL,
      payload: err?.response?.data?.message,
    });
  }
};

export const login = (email, password, history) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post(BaseURL + "/login", body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    setAuthToken(localStorage.getItem(TOKEN_NAME));

    history.push("/search");
  } catch (err) {
    console.log(err);
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const logout = (history) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    await axios.post(BaseURL + "/logout", config);
    dispatch({ type: LOGOUT });

    history.go("/login");
  } catch (err) {
    console.log(err);
    dispatch({
      type: LOGOUT_FAIL,
    });
  }
};
