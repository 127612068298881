export const Localization = {
    logoFilename: 'wheeltek_logo_auto.png',
    clientName: 'Wheeltek',
    footer: 'powered_by.png',
    navbarBackgroundColor: '#FE0101',
    tvsdPurple: "#6956ba",
    country: 'Philippines',
    countryAbbrev: 'PH',
    countryCode: '+63',
    zipcodeLength: 4,
    zipcodeValidationMsg: 'Philippines zipcode should have 4 characters. Ex: 0700, 1234',
    maxNumOfCallbacks: 3,
};
