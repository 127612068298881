import { MOBILE_COUNTRY_CODE } from "helpers/countrycode";
import { toastr } from "react-redux-toastr";
import { Localization } from "./config";

// export const BaseURL = 'https://kps1k56gh7.execute-api.ap-southeast-1.amazonaws.com/dev/v1'; //dev
export const BaseURL = 'https://lddg15du74.execute-api.ap-southeast-1.amazonaws.com/production/v1' //prod
// export const BaseURL = 'http://localhost:3001/dev/v1'; //localhost

// export const TOKEN_NAME = 'TVSD_DEDUPE_TOKEN_DEV'; // dev
export const TOKEN_NAME = 'TVSD_DEDUPE_TOKEN_PROD'; // prod

export const TOASTR_ERROR_OPTIONS = {
  timeOut: 600000000,
  onCloseButtonClick: () => {
    toastr.clean();
  },
  showCloseButton: true,
  closeOnToastrClick: false,
  icon: <img src={`${process.env.PUBLIC_URL}/images/error.svg`} alt="error" />
};

export const TOASTR_SUCCESS_OPTIONS = {
  timeOut: 3000,
  onCloseButtonClick: () => {
    toastr.clean();
  },
  showCloseButton: true,
  closeOnToastrClick: false,
  icon: <img src={`${process.env.PUBLIC_URL}/images/check-circle.svg`} alt="check-circle" />
};

export const INITIAL_FORM_VALUES = {
  first_name: "",
  middle_name: "",
  last_name: "",
  country_code: MOBILE_COUNTRY_CODE.find(
    (el) => el.id === Localization.countryAbbrev
  ),
  phone_number: "",
  date_of_birth: null,
  city: "",
  zipcode: "",
  first_name_2: "",
  middle_name_2: "",
  last_name_2: "",
  alternate_country_code: MOBILE_COUNTRY_CODE.find(
    (el) => el.id === Localization.countryAbbrev
  ),
  alternate_phone_number: "",
  alternate_address: "",
  province: "",
  district: "",
  street: "",
  address: "",
  comaker_name: "",
  comaker_address: "",
}

export const POLL_TIMER = 3000;
export const SEARCH_TIMEOUT = 150000; // 2 and a half minutes
export const MINIMUM_AGE = 21;
export const MAXIMUM_AGE = 55;