import { FormGroup } from "reactstrap";
import { Field } from "formik";
import styles from "styled-components";
import SelectDropdown from "components/elements/SelectDropDown";
import CountryCodeDropDown from "components/elements/CountryCodeDropDown";
import { MOBILE_COUNTRY_CODE } from "helpers/countrycode";
import { provinces } from "constant/provinces";

const OptionalField = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleBlur,
}) => {
  return (
    <OptionalFormGroupStyle>
      <div className="first_name_2">
        <FieldStyle
          name="first_name_2"
          type="text"
          placeholder="Enter First Name 2"
          onBlur={(event) => {
            handleBlur(event);
            setFieldValue(event.target.name, event.target.value.trim());
          }}
        />
        {touched.first_name_2 && errors.first_name_2 && (
          <div className="text-danger">{errors.first_name_2}</div>
        )}
      </div>
      <div className="middle_name_2">
        <FieldStyle
          name="middle_name_2"
          type="text"
          placeholder="Enter Middle Name 2"
          onBlur={(event) => {
            handleBlur(event);
            setFieldValue(event.target.name, event.target.value.trim());
          }}
        />
        {touched.middle_name_2 && errors.middle_name_2 && (
          <div className="text-danger">{errors.middle_name_2}</div>
        )}
      </div>
      <div className="last_name_2">
        <FieldStyle
          name="last_name_2"
          type="text"
          placeholder="Enter Last Name 2"
          onBlur={(event) => {
            handleBlur(event);
            setFieldValue(event.target.name, event.target.value.trim());
          }}
        />
        {touched.last_name_2 && errors.last_name_2 && (
          <div className="text-danger">{errors.last_name_2}</div>
        )}
      </div>
      <ContactStyle className="contact">
        <div className="alternate_country_code">
          <FieldStyle
            component={CountryCodeDropDown}
            name="country_code"
            data={MOBILE_COUNTRY_CODE}
            handleChange={(e: any) =>
              setFieldValue("alternate_country_code", e)
            }
            value={values.alternate_country_code}
          />
          {touched.alternate_country_code && errors.alternate_country_code && (
            <div className="text-danger">{errors.alternate_country_code}</div>
          )}
        </div>
        <div className="phone_number">
          <FieldStyle
            name="alternate_phone_number"
            type="text"
            placeholder="Enter Alternate Phone Number"
          />
          {touched.alternate_phone_number && errors.alternate_phone_number && (
            <div className="text-danger">{errors.alternate_phone_number}</div>
          )}
        </div>
      </ContactStyle>
      <div className="alternate_address">
        <FieldStyle
          name="alternate_address"
          type="text"
          placeholder="Enter Alternate Address"
          onBlur={(event) => {
            handleBlur(event);
            setFieldValue(event.target.name, event.target.value.trim());
          }}
        />
        {touched.alternate_address && errors.alternate_address && (
          <div className="text-danger">{errors.alternate_address}</div>
        )}
      </div>
      <div className="province">
        <Field
          component={SelectDropdown}
          name="province"
          data={provinces}
          handleChange={(e: any) => setFieldValue("province", e)}
          value={values.province}
          initActive="Select Province"
          id="province-dropdown"
        />
        {touched.province && errors.province && (
          <div className="mt-2 text-danger">{errors.province}</div>
        )}
      </div>
      <div className="district">
        <FieldStyle
          name="district"
          type="text"
          placeholder="Enter District"
          onBlur={(event) => {
            handleBlur(event);
            setFieldValue(event.target.name, event.target.value.trim());
          }}
        />
        {touched.district && errors.district && (
          <div className="text-danger">{errors.district}</div>
        )}
      </div>
      <div className="street">
        <FieldStyle
          name="street"
          type="text"
          placeholder="Enter Street"
          onBlur={(event) => {
            handleBlur(event);
            setFieldValue(event.target.name, event.target.value.trim());
          }}
        />
        {touched.street && errors.street && (
          <div className="text-danger">{errors.street}</div>
        )}
      </div>
      <div className="address">
        <FieldStyle
          name="address"
          type="text"
          placeholder="Enter Address"
          onBlur={(event) => {
            handleBlur(event);
            setFieldValue(event.target.name, event.target.value.trim());
          }}
        />
        {touched.address && errors.address && (
          <div className="text-danger">{errors.address}</div>
        )}
      </div>
      <div className="comaker_name">
        <FieldStyle
          name="comaker_name"
          type="text"
          placeholder="Enter Comaker Name"
          onBlur={(event) => {
            handleBlur(event);
            setFieldValue(event.target.name, event.target.value.trim());
          }}
        />
        {touched.comaker_name && errors.comaker_name && (
          <div className="text-danger">{errors.comaker_name}</div>
        )}
      </div>
      <div className="comaker_address">
        <FieldStyle
          name="comaker_address"
          type="text"
          placeholder="Enter Comaker Address"
          onBlur={(event) => {
            handleBlur(event);
            setFieldValue(event.target.name, event.target.value.trim());
          }}
        />
        {touched.comaker_address && errors.comaker_address && (
          <div className="text-danger">{errors.comaker_address}</div>
        )}
      </div>
    </OptionalFormGroupStyle>
  );
};

const OptionalFormGroupStyle = styles(FormGroup)`
	height: auto;
	padding-top: 20px;
	padding-bottom: 30px;
	display: grid;
	grid-auto-columns: 1fr; 
	grid-template-columns: 1fr 1fr 1fr; 
	gap: 25px 30px; 
	grid-template-areas: 
	"first_name_2 middle_name_2 last_name_2"
	"contact alternate_address province"
	"district street address"
	"comaker_name comaker_address ."; 
`;
const FieldStyle = styles(Field)`
	height: 55px;
	padding: 10px;
	width: 100%;
	border-width: thin;
	border
	margin-bottom: 5px;
	border-radius: 10px;
`;
const ContactStyle = styles.div`
	display: grid; 
	grid-auto-columns: 1fr; 
	grid-template-columns: 1fr 5fr; 
	grid-template-rows: 1fr; 
	gap: 0px 10px; 
	grid-template-areas: 
	  "alternate_country_code phone_number"; 
	grid-area: contact; 
`;

export default OptionalField;
