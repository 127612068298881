import Header from "components/layouts/Header";
import Sidebar from "components/layouts/Sidebar";
import styles from "styled-components";
import { Container, Card, CardBody } from "reactstrap";
import Breadcrumb from "components/elements/Breadcrumb";
import { useHistory } from "react-router";
import { Localization } from "constant/config";
import moment from "moment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { branches } from "constant/branches";

const CustomerDetail = (props) => {
  const customerData = props.location.state;
  const history = useHistory();

  if (!customerData) {
    history.replace("/search");
    return null;
  }

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      textAlign: "center",
    },
  }));

  const requiredField = [
    {
      label: "First Name",
      value: "first_name",
      formatter: (cell) => (cell ? cell.toUpperCase() : "-"),
    },
    {
      label: "Middle Name",
      value: "middle_name",
      formatter: (cell) => (cell ? cell.toUpperCase() : "-"),
    },
    {
      label: "Last Name",
      value: "last_name",
      formatter: (cell) => (cell ? cell.toUpperCase() : "-"),
    },
    {
      label: "Phone Number",
      value: "phone_number",
      formatter: (cell) => (cell ? `${Localization.countryCode} ${cell}` : "-"),
    },
    {
      label: "Date of Birth",
      value: "date_of_birth",
      formatter: (cell) =>
        moment(cell).isValid() ? moment(cell).format("DD/MM/YYYY") : "-",
    },
    {
      label: "City",
      value: "city",
      formatter: (cell) =>
        cell ? `${cell[0].toUpperCase()}${cell.slice(1).toLowerCase()}` : "-",
    },
    {
      label: "Zip Code",
      value: "zipcode",
      formatter: (cell) => (cell ? cell : "-"),
    },
    {
      label: "Company",
      value: "company",
      formatter: (cell) => (cell ? cell.toUpperCase() : "-"),
    },
    {
      label: "Branch Code",
      value: "branch",
      formatter: (cell) => (cell ? cell.toUpperCase() : "-"),
    },
    {
      label: "Branch Name",
      value: "branch",
      formatter: (cell) => (cell ? getBranchName(cell.toUpperCase()) || "-" : "-"),
    },
    {
      label: "Remarks",
      value: "remarks",
      formatter: (cell) => (cell ? cell : "-"),
    },
    // {
    //   label: "Status",
    //   value: "status",
    //   formatter: (cell) => (cell ? cell : "-"),
    // },
  ];

  const optionalField = [
    {
      label: "First Name 2",
      value: "first_name_2",
      formatter: (cell) => (cell ? cell.toUpperCase() : "-"),
    },
    {
      label: "Middle Name 2",
      value: "middle_name_2",
      formatter: (cell) => (cell ? cell.toUpperCase() : "-"),
    },
    {
      label: "Last Name 2",
      value: "last_name_2",
      formatter: (cell) => (cell ? cell.toUpperCase() : "-"),
    },
    {
      label: "Alternate Phone Number",
      value: "alternate_phone_number",
      formatter: (cell) => (cell ? `${Localization.countryCode} ${cell}` : "-"),
    },
    {
      label: "Alternate Address",
      value: "alternate_address",
      formatter: (cell) => (cell ? cell.toUpperCase() : "-"),
    },
    {
      label: "Province",
      value: "province",
      formatter: (cell) => (cell ? cell.toUpperCase() : "-"),
    },
    {
      label: "District",
      value: "district",
      formatter: (cell) =>
        cell ? `${cell[0].toUpperCase()}${cell.slice(1).toLowerCase()}` : "-",
    },
    {
      label: "Street",
      value: "street",
      formatter: (cell) => (cell ? cell.toUpperCase() : "-"),
    },
    {
      label: "Address",
      value: "address",
      formatter: (cell) => (cell ? cell.toUpperCase() : "-"),
    },
    {
      label: "Comaker Name",
      value: "comaker_name",
      formatter: (cell) => (cell ? cell.toUpperCase() : "-"),
    },
    {
      label: "Comaker Address",
      value: "comaker_address",
      formatter: (cell) => (cell ? cell.toUpperCase() : "-"),
    },
  ];

  const infoField = [
    {
      label: "Loans",
      value: "num_of_loans",
      formatter: (cell) => (cell ? cell : "-"),
    },
    {
      label: "Active Loans",
      value: "num_of_active_loans",
      formatter: (cell) => (cell ? cell : "-"),
    },
    {
      label: "Highest Recorded Bucket",
      value: "highest_recorded_bucket",
      formatter: (cell) => (cell ? cell : "-"),
      style: { color: "#e16060" },
    },
    {
      label: "External Default",
      value: "external_default",
      formatter: (cell) => (cell ? cell : "-"),
    },
    {
      label: "Maturity (Months)",
      value: "maturity",
      formatter: (cell) => (cell ? cell : "-"),
    },
  ];

  const FieldComponent = ({ field }) => (
    <div className="d-flex flex-column">
      <Label>{field.label}</Label>
      <Value style={field.style ? field.style : {}}>
        {field.formatter
          ? field.formatter(customerData[field.value])
          : customerData[field.value]}
      </Value>
    </div>
  );

  return (
    <Main>
      <Header />
      <Container
        style={{ marginTop: "49px", paddingLeft: 0, paddingRight: 0 }}
        fluid
      >
        <div className="d-flex h-100">
          <Sidebar />
          <Div className="d-flex flex-column vh-100 vw-100">
            <Breadcrumb
              data={[
                {
                  label: "Search Duplicate",
                  link: "/search",
                },
                {
                  label: "Customer",
                },
              ]}
            />
            <Title>
              {`${customerData.first_name?.toUpperCase() || ""} ${
                customerData.middle_name
                  ? customerData.middle_name?.toUpperCase() + " "
                  : ""
              }${customerData.last_name?.toUpperCase() || ""}`}
              {customerData.blacklisted ? (
                <BootstrapTooltip title="Blacklisted">
                  <img
                    style={{
                      width: "25px",
                      height: "auto",
                      marginLeft: "13px",
                      marginBottom: "7px",
                    }}
                    src={`${process.env.PUBLIC_URL}/images/blacklist.svg`}
                    alt="blacklist"
                  />
                </BootstrapTooltip>
              ) : null}
            </Title>
            {/* {customerData.acct_number ? ( */}
            <AcctNumber>
              {customerData.acct_number || (
                <div style={{ marginLeft: "3px" }}>-</div>
              )}
            </AcctNumber>
            {/* ) : ( */}
            {/* "" */}
            {/* )} */}
            <Score>{`${customerData.score}%`}</Score>
            <CardStyle>
              <CardBody style={{ paddingBottom: "0px" }}>
                <DetailStyle>
                  <RequiredStyle>
                    {requiredField.map((field) => (
                      <FieldComponent field={field} />
                    ))}
                  </RequiredStyle>
                  <OptionalStyle>
                    {optionalField.map((field) => (
                      <FieldComponent field={field} />
                    ))}
                  </OptionalStyle>
                  <InfoStyle>
                    {infoField.map((field) => (
                      <FieldComponent field={field} />
                    ))}
                  </InfoStyle>
                </DetailStyle>
              </CardBody>
            </CardStyle>
          </Div>
        </div>
      </Container>
    </Main>
  );
};

const getBranchName = (branchCode) => {
  for (const branch of branches) {
    if (branch.code === branchCode) {
      return branch.name;
    }
  }

  return null;
}

const Main = styles.div`
	background-color: #F6F6F6;
	display: flex;
	height: 100%;
	width: 100%;
	min-height: 100vh;
`;

const Div = styles.div`
	padding: 30px 20px 850px calc(200px + 20px);
`;

const Title = styles.h1`
  font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 38px;
	color: #333333;
  margin-left: 5px;
`;

const AcctNumber = styles.h2`
  font-family: 'Roboto', sans-serif;
  font-size: 23px;
  color: black;
  font-weight: 400;
  margin-left: 5px;
  margin-top: -15px;
  margin-bottom: -5px;
`;

const Score = styles.h2`
  font-family: 'Roboto', sans-serif;
  font-size: 26px;
  color: #1ba886;
	padding-bottom: 10px;
  margin-left: 5px;
`;
const CardStyle = styles(Card)`
	width: 100%;
	box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.05) !important;
	border-radius: 10px !important;
	@media (max-width: 414px) {
	max-width: 100%;
	background: #fbf9fe
	}
`;
const DetailStyle = styles.div`
    padding: 7px;
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr; 
    grid-template-rows: 3fr 4fr 2fr; 
    gap: 0px 0px; 
    grid-template-areas: 
        "required"
        "optional"
        "info";
`;
const RequiredStyle = styles.div`
    padding-bottom: 25px;
    border-width: thin !important;
    border-bottom: solid;
    border-color: #d8daff;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr; 
    gap: 15px 0px; 
    grid-template-areas: 
        "first_name middle_name last_name"
        "phone_number dob city"
        "zipcode company branch_code"
        "branch_name remarks status"; 
    grid-area: required;
`;
const OptionalStyle = styles.div`
    padding-top: 25px;
    padding-bottom: 25px;
    border-width: thin !important;
    border-bottom: solid;
    border-color: #d8daff;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr 1fr; 
    gap: 15px 0px; 
    grid-template-areas: 
        "first_name_2 middle_name_2 last_name_2"
        "alternate_phone_number alternate_address province"
        "district street address"
        "comaker_name comaker_address ."; 
    grid-area: optional; 
`;
const InfoStyle = styles.div`
    padding-top: 25px;
    padding-bottom: 15px;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 15px 0px; 
    grid-template-areas: 
        "loans active_loans highest_recorded_bucket"
        "external_default maturity ."; 
    grid-area: info;
`;
const Label = styles.div`
    color: #999999;
    font-weight: 500;
    font-size: 13px;
`;
const Value = styles.div`
    font-weight: 500;
    font-size: 14px;
`;

export default CustomerDetail;
