import { useContext, useEffect, useRef } from "react";
import AppContext from "helpers/context";
import { Link } from "react-router-dom";
import { Button, Label } from "reactstrap";
import styles from "styled-components";
import TvsTable from "../../components/elements/Table";
import { Localization } from "constant/config";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { submitFeedback } from "actions/feedback";
import { toastr } from "react-redux-toastr";
import { TOASTR_ERROR_OPTIONS, TOASTR_SUCCESS_OPTIONS } from "constant";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    textAlign: "center",
  },
}));

const Records = ({
  submitFeedback,
  feedback,
  handleDownload,
  timerId,
  timeOutId,
}) => {
  const { resultList, setResultList } = useContext(AppContext);

  const initalRender = useRef(true);

  const columns = [
    {
      dataField: "id",
      text: "No.",
      formatter: (cell, row) => resultList.indexOf(row) + 1,
    },
    {
      dataField: "customer_name",
      text: "Customer Name",
      formatter: (cell, row) => {
        return (
          <div>
            <Link
              onClick={() => {
                // clear both timers when going to customer detail page
                clearInterval(timerId);
                clearTimeout(timeOutId);
              }}
              to={{ pathname: "/customer", state: row }}
              style={{ textDecoration: "none" }}
            >{`${row.first_name?.toUpperCase() || ""} ${
              row.middle_name ? row.middle_name?.toUpperCase() + " " : ""
            }${row.last_name?.toUpperCase() || ""}`}</Link>
            {row.blacklisted ? (
              <BootstrapTooltip title="Blacklisted">
                <img
                  style={{ marginLeft: "6px", marginBottom: "2px" }}
                  src={`${process.env.PUBLIC_URL}/images/blacklist.svg`}
                  alt="blacklist"
                />
              </BootstrapTooltip>
            ) : null}
          </div>
        );
      },
    },
    {
      dataField: "acct_number",
      text: "Account No.",
      formatter: (cell) => (cell ? cell.toUpperCase() : "-"),
    },
    {
      dataField: "date_of_birth",
      text: "Date of Birth",
      formatter: (cell, row) =>
        moment(cell).isValid() ? moment(cell).format("DD/MM/YYYY") : "-",
    },
    {
      dataField: "city",
      text: "City",
      formatter: (cell) =>
        cell ? `${cell[0].toUpperCase()}${cell.slice(1)?.toLowerCase()}` : "-",
    },
    {
      dataField: "province",
      text: "Province",
      formatter: (cell) => (cell ? cell.toUpperCase() : "-"),
    },
    {
      dataField: "street",
      text: "Street",
      formatter: (cell) => (cell ? cell.toUpperCase() : "-"),
    },
    {
      dataField: "phone_number",
      text: "Phone Number",
      formatter: (cell) => (cell ? `${Localization.countryCode} ${cell}` : "-"),
    },
    {
      dataField: "num_of_loans",
      text: "Loans",
      formatter: (cell) => (cell ? cell : "-"),
    },
    {
      dataField: "num_of_active_loans",
      text: "Active Loans",
      formatter: (cell) => (cell ? cell : "-"),
    },
    {
      dataField: "highest_recorded_bucket",
      text: "Highest Recorded Bucket",
      formatter: (cell) => (cell ? cell : "-"),
      style: {
        color: "#e16060",
      },
    },
    {
      dataField: "external_default",
      text: "External Default",
      formatter: (cell) => (cell ? cell : "-"),
    },
    {
      dataField: "score",
      text: "Score",
      formatter: (cell, row) =>
        cell ? (
          <span
            style={{ color: "#27ad8d", fontWeight: 500 }}
          >{`${cell}%`}</span>
        ) : (
          "-"
        ),
    },
    {
      dataField: "feedback",
      text: "Feedback",
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <img
              src={`${process.env.PUBLIC_URL}/images/${
                cell === 1 ? "thumbs_up_active.svg" : "thumbs_up.svg"
              }`}
              alt="thumbsup"
              style={{ marginRight: "10px" }}
              className={feedback.loading ? "" : "clickable"}
              onClick={
                cell !== 1 && !feedback.loading
                  ? () => submitFeedback(row.id, 1)
                  : null
              }
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/${
                cell === -1 ? "thumbs_down_active.svg" : "thumbs_down.svg"
              }`}
              alt="thumbsdown"
              className={feedback.loading ? "" : "clickable"}
              onClick={
                cell !== -1 && !feedback.loading
                  ? () => submitFeedback(row.id, -1)
                  : null
              }
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    // prevent running on initial render
    if (initalRender.current) {
      initalRender.current = false;
      return;
    }

    if (feedback.loading) {
      return;
    }

    if (feedback.error) {
      toastr.error("", feedback.error, TOASTR_ERROR_OPTIONS);
      return;
    }

    if (feedback.data) {
      setResultList((results) =>
        results.map((result) => {
          if (result.id === feedback.data.resultId) {
            return { ...result, feedback: feedback.data.value };
          }
          return result;
        })
      );
      toastr.success("", feedback.data.message, TOASTR_SUCCESS_OPTIONS);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedback]);

  return (
    <div>
      <TableHeaderStyle>
        <div>
          <Title>Matching Customer Records</Title>
          <div className="table-note">
            <Label className="label-color">
              {`Top ${resultList.length} Matches Found`}
            </Label>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <ButtonStyle size="sm" onClick={handleDownload}>
            <DownloadIcon
              src={`${process.env.PUBLIC_URL}/images/icon_download.svg`}
              alt="download"
            />
            <span style={{ fontSize: "12px", marginRight: "2px" }}>
              Download Results
            </span>
          </ButtonStyle>
        </div>
      </TableHeaderStyle>
      <div>
        <TvsTable columns={columns} data={resultList} keyField={"id"} />
      </div>
      <div className="pb-5"></div>
    </div>
  );
};

const TableHeaderStyle = styles.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
`;
const Title = styles.h1`
	font-weight: 400;
	font-size: 25px;
	color: #333333;
`;
const ButtonStyle = styles(Button)`
    background-color: ${Localization.tvsdPurple} !important;
    border-radius: 5px !important;
    margin-right: 2px;
`;
const DownloadIcon = styles.img`
    height: 18px;
    margin-right: 2px;
    margin-bottom: 2px;
`;

Records.propTypes = {
  submitFeedback: PropTypes.func.isRequired,
  feedback: PropTypes.object,
};

const mapStateToProps = (state) => ({
  feedback: state.feedback,
});
export default connect(mapStateToProps, {
  submitFeedback,
})(Records);
