/* eslint-disable react/no-array-index-key */
import { useState, useEffect } from "react"
import styled from "styled-components"
import {
	Dropdown,
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
} from "reactstrap"

interface Props {
	handleChange: (x: CountryCodeI) => void
	data: CountryCodeI[]
	initActive?: CountryCodeI
	value: CountryCodeI
}

interface CountryCodeI {
	label: string
	value: string
	id: string
}

const CountryCodeDropdown = ({
	data,
	handleChange,
	value,
	initActive = { label: "Philippines", value: "+63", id: "PH" },
}: Props) => {
	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

	const toggle = () => setDropdownOpen((prevState) => !prevState)
	const [active, setActive] = useState<CountryCodeI>(initActive)

	useEffect(() => {
		setActive(value)
	}, [value])

	const handleSelectItem = (item: CountryCodeI) => {
		if (item === initActive)
			setActive({ label: "Philippines", value: "+63", id: "PH" })
		else setActive(item)
		handleChange(item)
	}

	const renderItem = (item: CountryCodeI) => {
		return (
			<DropdownItem
				key={item.id}
				onClick={() => handleSelectItem(item)}
				className="d-flex"
			>
				<span style={{ flex: 1 }}>{item.value}</span>
				<span style={{ flex: 4, minWidth: '0px' }}>{item.label}</span>
			</DropdownItem>
		)
	}

	return (
		<Dropdown
			isOpen={dropdownOpen}
			toggle={toggle}
			style={{
				border: "thin solid ",
				height: "56px",
				borderRadius: "8px",
				background: "#fff",
			}}
		>
			<DropdownToggleStyle
				caret
				color="x"
				className="p-3 border-0 d-flex justify-content-between align-items-center"
			>
				{active.value} {active.id}
			</DropdownToggleStyle>
			<DropdownMenuStyle>
				{data.map((item) => renderItem(item))}
			</DropdownMenuStyle>
		</Dropdown>
	)
}

export const DropdownToggleStyle = styled(DropdownToggle)`
	&.btn {
		width: 100%;
		color: #263238;
		font-size: 16px;
		font-weight: 400;
		&:hover,
		&:focus,
		&:visited {
			text-decoration: none;
		}
	}
`
export const DropdownMenuStyle = styled(DropdownMenu)`
	margin-top: 0.5rem;
	min-width: 450px !important;
`
export default CountryCodeDropdown
