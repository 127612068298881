import { useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Localization } from "constant/config";
import { logout } from "../../actions/auth";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import store from "../../store.js";
import { loadUser } from "../../actions/auth";

const Header = ({ logout, auth }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const history = useHistory();

  const handleLogout = () => {
    logout(history);
  };

  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Div>
      <Container fluid={true}>
        <div className="d-flex justify-content-between">
          <div />
          <div className="d-flex justify-content-center">
            <div className="pt-1">
              <DropdownStyle isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggleType>
                  <div className="d-flex align-items-center">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/user_profile.svg`}
                      alt="avatar"
                      style={{ marginRight: "10px" }}
                    />
                    {auth?.user?.first_name} {auth?.user?.last_name}
                    <div
                      className={!dropdownOpen ? "pb-1" : ""}
                      style={{ paddingLeft: "20px" }}
                    >
                      {!dropdownOpen ? (
                        <div className="ml-2 header-arrow-down" />
                      ) : (
                        <div className="ml-2 header-arrow-up" />
                      )}
                    </div>
                  </div>
                </DropdownToggleType>
                <DropdownMenuStyle container="body">
                  <DropdownItem onClick={handleLogout}>{"Logout"}</DropdownItem>
                </DropdownMenuStyle>
              </DropdownStyle>
            </div>
          </div>
        </div>
      </Container>
    </Div>
  );
};

const DropdownMenuStyle = styled(DropdownMenu)`
  width: 237px;
`;

const Div = styled.div`
  background: ${Localization.navbarBackgroundColor};
  padding: 5px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
`;
const DropdownStyle = styled(Dropdown)`
  font-weight: 400;
  &:hover,
  &:focus,
  &:visited {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: 0;
  }
`;
const DropdownToggleType = styled(DropdownToggle)`
  background: transparent;
  border: none;
  border-radius: 0;
  font-family: "Roboto", sans-serif;
  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: none !important;
    background: rgba(255, 255, 255, 0.3) !important;
    border: none;
    box-shadow: none !important;
    outline: 0;
  }
`;

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logout })(withRouter(Header));
