export const provinces = [
    "Abra",
    "Agusan Del Norte",
    "Agusan Del Sur",
    "Aklan",
    "Albay",
    "Antique",
    "Apayao",
    "Aurora",
    "Basilan",
    "Bataan",
    "Batanes",
    "Batangas",
    "Benguet",
    "Biliran",
    "Bohol",
    "Bukidnon",
    "Bulacan",
    "Cagayan",
    "Camarines Norte",
    "Camarines Sur",
    "Capiz",
    "Catanduanes",
    "Cavite",
    "Cebu",
    "Compostela Valley",
    "Davao Del Norte",
    "Davao Del Sur",
    "Davao Oriental",
    "Eastern Samar",
    "Guimaras",
    "Ifugao",
    "Ilocos Norte",
    "Ilocos Sur",
    "Iloilo",
    "Isabela",
    "Kalinga",
    "La Union",
    "Laguna",
    "Lanao Del Norte",
    "Lanao Del Sur",
    "Leyte",
    "Maguindanao",
    "Marinduque",
    "Masbate",
    "Metro Manila",
    "Misamis Occidental",
    "Misamis Oriental",
    "Mountain Province",
    "Negros Occidental",
    "Negros Oriental",
    "North Cotabato",
    "Northern Samar",
    "Nueva Ecija",
    "Nueva Vizcaya",
    "Occidental Mindoro",
    "Oriental Mindoro",
    "Palawan",
    "Pampanga",
    "Pangasinan",
    "Quezon",
    "Quirino",
    "Rizal",
    "Romblon",
    "Sarangani",
    "Siquijor",
    "Sorsogon",
    "South Cotabato",
    "Southern Leyte",
    "Sultan Kudarat",
    "Sulu",
    "Surigao Del Norte",
    "Surigao Del Sur",
    "Tarlac",
    "Tawi-tawi",
    "Western Samar",
    "Zambales",
    "Zamboanga Del Norte",
    "Zamboanga Del Sur"
]