import BootstrapTable from "react-bootstrap-table-next"


const defaultRowStyle = () => {
  return {
    background: '#fff',
    color: '#5A6168',
  };
};

const Table = ({columns, data, keyField, rowStyle = defaultRowStyle} : {columns: any, data: any, keyField: string, rowStyle?: any}) => {

  return (
    <BootstrapTable columns={columns} data={data} keyField={keyField} bordered={false} rowStyle={rowStyle}/>
  )
}

export default Table
