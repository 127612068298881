import axios from "axios";
import {
  GET_RESULT_COUNT,
  GET_RESULT_COUNT_SUCCESS,
  GET_RESULT_COUNT_FAIL,
  GET_RESULT_LIST,
  GET_RESULT_LIST_SUCCESS,
  GET_RESULT_LIST_FAIL,
} from "./types";
import { BaseURL } from "../constant";

export const getResultCount = (userInputInfoId) => async (dispatch) => {
  // console.log("calling result count");
  dispatch({
    type: GET_RESULT_COUNT,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      BaseURL + `/result-count/${userInputInfoId}}`,
      config
    );

    const { result_count, num_of_callbacks, ...rest } = res.data;
    dispatch({
      type: GET_RESULT_COUNT_SUCCESS,
      payload: {
        resultCount: result_count,
        numOfCallbacks: num_of_callbacks,
        ...rest,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_RESULT_COUNT_FAIL,
      payload: {
        error: err?.response?.data?.message,
      },
    });
  }
};

export const getResultList = (userInputInfoId) => async (dispatch) => {
  // console.log("calling result list");
  dispatch({
    type: GET_RESULT_LIST,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      BaseURL + `/result-list/${userInputInfoId}}`,
      config
    );

    const { result_list, ...rest } = res.data;
    dispatch({
      type: GET_RESULT_LIST_SUCCESS,
      payload: {
        resultList: result_list,
        ...rest,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_RESULT_LIST_FAIL,
      payload: {
        error: err?.response?.data?.message,
      },
    });
  }
};