import axios from "axios";
import {
  SUBMIT_FEEDBACK,
  SUBMIT_FEEDBACK_FAIL,
  SUBMIT_FEEDBACK_SUCCESS,
} from "./types";
import { BaseURL } from "../constant";

export const submitFeedback = (resultId, feedbackValue) => async (dispatch) => {
  dispatch({
    type: SUBMIT_FEEDBACK,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ resultId, feedbackValue });

  try {
    const res = await axios.post(BaseURL + "/submit-feedback", body, config);

    const { message, ...rest } = res.data;

    dispatch({
      type: SUBMIT_FEEDBACK_SUCCESS,
      payload: {
        message,
        value: feedbackValue,
        resultId,
        ...rest,
      },
    });
  } catch (err) {
    console.log(err);

    dispatch({
      type: SUBMIT_FEEDBACK_FAIL,
      payload: {
        error: err?.response?.data?.message,
      },
    });
  }
};
