import {
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "../actions/types";
import { TOKEN_NAME } from "constant";

const initialState = {
  token: localStorage.getItem(TOKEN_NAME),
  isAuthenticated: false,
  loading: true,
  user: null,
  error: false,
};

export default function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        user: payload.user,
        isAuthenticated: true,
        loading: false,
        error: false,
      };
    case LOAD_USER_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem(TOKEN_NAME, payload.acc_token);
      return {
        ...state,
        token: payload.acc_token,
        user: payload.user,
        isAuthenticated: true,
        loading: false,
        error: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        error: true,
      };
    case LOGOUT:
      localStorage.removeItem(TOKEN_NAME);
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        error: false,
      };
    default:
      return state;
  }
}
