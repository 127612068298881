import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "helpers/context";
import Header from "components/layouts/Header";
import Sidebar from "components/layouts/Sidebar";
import Records from "./records";
import * as yup from "yup";
import { Formik, FormikProps, Form } from "formik";
import {
  Button,
  Container,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import styles from "styled-components";
import { Localization } from "constant/config";
import RequiredField from "components/form/RequiredField";
import OptionalField from "components/form/OptionalField";
import { PHONE_VALIDATE } from "helpers/validate";
import { cities } from "../../constant/cities";
import { provinces } from "../../constant/provinces";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { searchDuplicate } from "actions/search";
import { getResultCount, getResultList } from "actions/result";
import { toastr } from "react-redux-toastr";
import {
  INITIAL_FORM_VALUES,
  POLL_TIMER,
  SEARCH_TIMEOUT,
  TOASTR_ERROR_OPTIONS,
} from "constant";
import { useScreenshot, createFileName } from "use-react-screenshot";
import moment from "moment";

interface DedupeFormValue {
  first_name: string;
  middle_name: string;
  last_name: string;
  country_code: string;
  phone_number: string;
  date_of_birth: string;
  city: string;
  zipcode: string;
  first_name_2: string;
  middle_name_2: string;
  last_name_2: string;
  country_code_2: string;
  alternate_phone_number: string;
  alternate_address: string;
  province: string;
  district: string;
  street: string;
  address: string;
  comaker_name: string;
  comaker_address: string;
}

const Dedupe = ({
  searchDuplicate,
  search,
  getResultCount,
  getResultList,
  result,
}) => {
  const {
    submitted,
    setSubmitted,
    showOptionalField,
    setShowOptionalField,
    searchFields,
    setSearchFields,
    lastSearchedAt,
    setLastSearchedAt,
    searchCompleted,
    setSearchCompleted,
    resultCount,
    setResultCount,
    resultList,
    setResultList,
  } = useContext(AppContext);

  const imageRef = useRef<HTMLDivElement>(null);
  const formikRef = useRef(null);
  const [image, captureTableImage] = useScreenshot();
  const [showModal, setShowModal] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [timeOutId, setTimeOutId] = useState(null);

  const onSubmit = (values) => {
    setLastSearchedAt("");
    setResultCount(0);
    setResultList([]);
    setSearchCompleted(false);
    setShowOptionalField(false);
    setSearchFields(values);
    setSubmitted(true);
    searchDuplicate({
      ...values,
      country_code: values["country_code"]["value"],
      alternate_country_code: values["alternate_country_code"]["value"],
      date_of_birth: moment(values["date_of_birth"]).format('YYYY-MM-DD')
    });
  };

  useEffect(() => {
    if (search.loading) {
      return;
    }

    if (!searchCompleted && search.userInputInfoId) {
      // start searching
      setLastSearchedAt(processLastSearchedAt(search.lastSearchedAt));
      formikRef.current.setSubmitting(false);
      getResultCount(search.userInputInfoId);

      const id = setInterval(() => {
        getResultCount(search.userInputInfoId);
      }, POLL_TIMER);

      setTimerId(id);
      setTimeOutId(setTimeout(handleSearchTimeOut, SEARCH_TIMEOUT, id));
    } else if (search.error) {
      formikRef.current.setSubmitting(false);
      toastr.error(
        "Error while searching: ",
        search.error,
        TOASTR_ERROR_OPTIONS
      );
      setSearchCompleted(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (result.resultCount > resultCount) {
      // new results available, retrieve result list
      getResultList(search.userInputInfoId);
      setResultCount(result.resultCount);
    }

    if (result.numOfCallbacks >= Localization.maxNumOfCallbacks) {
      // search completed
      clearInterval(timerId);
      clearTimeout(timeOutId);
      setSearchCompleted(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  useEffect(() => {
    if (result.resultList?.length > resultList.length) {
      setResultList(result.resultList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.resultList?.length]);

  const handleSearchTimeOut = (id) => {
    setShowModal(true);
    clearInterval(id);
    setSearchCompleted(true);
  };

  const clearForm = (resetForm) => {
    resetForm({
      values: INITIAL_FORM_VALUES,
    });
  };

  const handleDownload = () => {
    captureTableImage(imageRef.current).then(download);
  };

  const download = (
    image: string,
    { name = "deduplication_result", extension = "jpg" } = {}
  ) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const processLastSearchedAt = (lastSearchedAt: any) => {
    if (!lastSearchedAt || lastSearchedAt.length <= 0) {
      return "";
    }

    let lastSearchedAtString = "";

    for (const search of lastSearchedAt) {
      lastSearchedAtString += `[${formatTitleCase(
        search.branch
      )}. By ${formatTitleCase(search.first_name)} ${formatTitleCase(
        search.last_name
      )} on ${moment(search.timestamp).format("DD MMM YYYY")}, ${moment(
        search.timestamp
      ).format("h:mm A")}], `;
    }

    lastSearchedAtString = lastSearchedAtString.slice(0, -2);

    return lastSearchedAtString;
  };

  const formatTitleCase = (text: string) => {
    if (text.length <= 1) {
      return text?.toUpperCase() || "";
    }

    return text[0].toUpperCase() + text.slice(1).toLowerCase();
  };
  const searchSchema = yup.object({
    first_name: yup
      .string()
      .trim()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .required("Please enter a first name"),
    middle_name: yup
      .string()
      .trim()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name"),
    last_name: yup
      .string()
      .trim()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .required("Please enter a last name"),
    country_code: yup.object().required("Please enter a country code"),
    phone_number: yup
      .string()
      .matches(/^\d+$/, "Phone number should only contain numbers")
      .required("Please enter a phone number")
      .when("country_code", (country_code: any, schema: any) => {
        const length = PHONE_VALIDATE.find(
          (el) => el.value === country_code.value
        )?.validate?.length;
        if (length)
          return schema.test(
            "length",
            "Please enter a valid phone number",
            (val: any) => val?.length === length
          );
        return schema;
      }),
    date_of_birth: yup
      .string()
      .required("Please enter a date of birth")
      .nullable(),
    zipcode: yup
      .string()
      .trim()
      .length(Localization.zipcodeLength, Localization.zipcodeValidationMsg)
      .matches(/^[A-Za-z0-9]*$/, "Please enter a valid zip code")
      .required("Please enter a zip code"),
    city: yup
      .string()
      .trim()
      .test(
        "existCheck",
        "Please enter a city",
        (value) =>
          value &&
          cities
            .map((el: any) => el.toLowerCase())
            .includes(value.toLowerCase())
      )
      .required("Please enter a city"),
    first_name_2: yup
      .string()
      .trim()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name"),
    middle_name_2: yup
      .string()
      .trim()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name"),
    last_name_2: yup
      .string()
      .trim()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name"),
    alternate_country_code: yup.object(),
    alternate_phone_number: yup
      .string()
      .matches(/^\d+$/, "Phone number should only contain numbers")
      .when(
        ["alternate_country_code"],
        (alternate_country_code: any, schema: any) => {
          const length = PHONE_VALIDATE.find(
            (el) => el.value === alternate_country_code.value
          )?.validate?.length;
          if (length)
            return schema.test(
              "length",
              "Please enter a valid phone number",
              (val: any) => !val || val?.length === length
            );
          return schema;
        }
      ),
    alternate_address: yup.string().trim(),
    province: yup
      .string()
      .trim()
      .test(
        "existCheck",
        "Please enter a province",
        (value) =>
          !value ||
          (value &&
            provinces
              .map((el: any) => el.toLowerCase())
              .includes(value.toLowerCase()))
      ),
    district: yup.string().trim(),
    street: yup.string().trim(),
    address: yup.string().trim(),
    comaker_name: yup.string().trim(),
    comaker_address: yup.string().trim(),
  });

  return (
    <Main>
      <Header />
      <Container
        style={{ marginTop: "49px", paddingLeft: 0, paddingRight: 0 }}
        fluid
      >
        <div className="d-flex h-100">
          <Sidebar />
          <Div className="d-flex flex-column vw-100" ref={imageRef}>
            <Title style={submitted ? {} : { paddingBottom: "40px" }}>
              Search Duplicate
            </Title>
            {submitted ? (
              <span
                style={{ paddingBottom: "40px" }}
              >{`Last Searched at Branch: ${lastSearchedAt || "-"}`}</span>
            ) : null}
            <CardStyle>
              <CardBody style={{ paddingBottom: "0px" }}>
                <Formik
                  innerRef={(instance) => {
                    formikRef.current = instance;
                  }}
                  initialValues={searchFields}
                  onSubmit={onSubmit}
                  validationSchema={searchSchema}
                >
                  {(props: FormikProps<DedupeFormValue>) => {
                    const {
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      resetForm,
                      setFieldValue,
                      handleBlur,
                    } = props;
                    return (
                      <Form autoComplete="off" className="mx-3">
                        <RequiredField
                          values={values}
                          touched={touched}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                        />
                        {showOptionalField ? (
                          <OptionalField
                            values={values}
                            touched={touched}
                            errors={errors}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                          />
                        ) : null}
                        <CardBody
                          className={`d-flex justify-content-between ${
                            !showOptionalField ? "p-2 pb-3" : ""
                          }`}
                        >
                          <ExpandButton
                            onClick={() =>
                              setShowOptionalField((prevState) => !prevState)
                            }
                          >
                            {!showOptionalField ? (
                              <div className="d-flex">
                                <div
                                  className="header-arrow-down-purple"
                                  style={{
                                    marginTop: "6px",
                                    marginRight: "10px",
                                  }}
                                />
                                More
                              </div>
                            ) : (
                              <div className="d-flex">
                                <div
                                  className="header-arrow-up-purple"
                                  style={{
                                    marginTop: "10px",
                                    marginRight: "10px",
                                  }}
                                />
                                Less
                              </div>
                            )}
                          </ExpandButton>
                          <div className="d-inline-flex">
                            <OptionButton
                              onClick={() => clearForm(resetForm)}
                              disabled={isSubmitting || !searchCompleted}
                              style={{
                                color: Localization.tvsdPurple,
                                backgroundColor: "inherit",
                              }}
                            >
                              Clear All
                            </OptionButton>
                            <OptionButton
                              type="submit"
                              disabled={isSubmitting || !searchCompleted}
                              style={{
                                backgroundColor: Localization.tvsdPurple,
                                marginLeft: "15px",
                                color: "white",
                              }}
                            >
                              {isSubmitting || !searchCompleted ? (
                                <>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span style={{ paddingLeft: "8px" }}>
                                    Searching...
                                  </span>
                                </>
                              ) : (
                                "Search"
                              )}
                            </OptionButton>
                          </div>
                        </CardBody>
                      </Form>
                    );
                  }}
                </Formik>
              </CardBody>
            </CardStyle>
            {submitted ? (
              resultCount ? (
                <Records
                  handleDownload={handleDownload}
                  timerId={timerId}
                  timeOutId={timeOutId}
                />
              ) : searchCompleted ? (
                "No results were found."
              ) : null
            ) : null}
          </Div>
        </div>
      </Container>
      <Modal
        isOpen={showModal}
        centered
        size="md"
        style={{
          margin: "auto",
        }}
      >
        <ModalHeader className="text-center flex justify-content-center">
          <div
            style={{
              fontWeight: 500,
              fontSize: "20px",
              color: "#000",
            }}
          >
            Search has timed out.
          </div>
        </ModalHeader>
        <ModalBody className="p-4 text-center flex justify-content-center">
          Please refine your search by adding more details and try again.
        </ModalBody>
        <ModalFooter className="text-center justify-content-center">
          <Button
            onClick={() => setShowModal(false)}
            color="primary"
            className="px-5 ml-3"
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </Main>
  );
};

const Main = styles.div`
  background-color: #F6F6F6;
	display: flex;
	height: 100%;
	width: 100%;
	min-height: 100vh;
	overflow-x: hidden;
`;
const Div = styles.div`
	padding: 40px 20px 0px calc(200px + 20px);
`;
const Title = styles.h1`
  font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 38px;
	color: #333333;
`;
const CardStyle = styles(Card)`
	width: 100%;
	margin-bottom: 40px;
	box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.05) !important;
	border-radius: 10px !important;
	@media (max-width: 414px) {
	max-width: 100%;
	background: #fbf9fe
	}
`;
const ExpandButton = styles(Button)`
	background-color: inherit !important;
	border: none;
	color: ${Localization.tvsdPurple} !important;
	font-weight: 500;
	padding: 0px !important;
`;
const OptionButton = styles(Button)`
	font-weight: 500;
	font-size: 14px !important;
	width: 130px;
	height: 43px;
	border-radius: 5px !important;
	padding: 0px 10px 0px 10px !important;
  border-color: #6956BA;
  &.disabled {
    border-color: #6956BA;
  }
  &:focus {
    border-color: #6956BA;
  }
`;

Dedupe.propTypes = {
  searchDuplicate: PropTypes.func.isRequired,
  search: PropTypes.object,
  getResultCount: PropTypes.func.isRequired,
  getResultList: PropTypes.func.isRequired,
  result: PropTypes.object,
};

const mapStateToProps = (state) => ({
  search: state.search,
  result: state.result,
});
export default connect(mapStateToProps, {
  searchDuplicate,
  getResultCount,
  getResultList,
})(Dedupe);
