import { Button, ListGroup, ListGroupItem } from "reactstrap"
import { Localization } from "constant/config"
import styled from "styled-components"
import { Link, useHistory, useRouteMatch } from "react-router-dom"

const Sidebar = () => {
	const history = useHistory()

	const menus = (() => {
		return [
			{
				name: "Search Duplicate",
				href: "/search",
				link: "/search",
				icon: "deduplication_icon.svg",
			},
		]
	})()

	const renderNavMenu = (el: any) => {
		return (
			<div className="d-flex justify-content-between">
				<div className="d-flex">
					{el?.icon && (
						<img
							className="align-self-center"
							src={`${process.env.PUBLIC_URL}/images/${el.icon}`}
							alt="icon"
							width="28"
							height="28"
						/>
					)}
					{!el?.link ? (
						// eslint-disable-next-line jsx-a11y/click-events-have-key-events
						<div className="ml-3 align-self-center" aria-hidden="true">
							{el.name}
						</div>
					) : (
						<Button
							color="text"
							onClick={(event) => {
								event.stopPropagation()
								history.push(el.link)
							}}
						>
							{el.name}
						</Button>
					)}
				</div>
			</div>
		)
	}

	const route = useRouteMatch()

	const activeRoute = route.path === "/" ? "/dashboard" : route.path

	return (
		<SidebarComponent className="p-0 d-flex flex-column justify-content-between">
			<div style={{ textAlign: "center" }}>
				<Link to="/search">
					<img
						src={`${process.env.PUBLIC_URL}/images/${Localization.logoFilename}`}
						alt={Localization.clientName}
						height="55"
						width="90%"
						className="mt-3"
					/>
				</Link>
				<ListGroupStyle>
					{menus.map((el: any) => (
						<>
							<ListGroupItem
								tag={el.href ? "a" : "div"}
								href={el?.href}
								key={el.name}
								className={`parent ${
									activeRoute.includes(el.link) ? "is-active" : ""
								}`}
							>
								{renderNavMenu(el)}
								{el?.items && (
									<ListGroupStyle>
										{el?.items?.map((child: any, index: number) => (
											<ListGroupItem
												key={child.href}
												className={`sub ${
													activeRoute.includes(child.link) ? "is-active" : ""
												}`}
											>
												<ul style={{ paddingLeft: "20px" }}>
													<li
														style={{
															paddingTop: "12px",
															paddingBottom: "12px",
															listStyle: "disc",
															color: "#999999",
														}}
													>
														{renderNavMenu(child)}
													</li>
												</ul>
											</ListGroupItem>
										))}
									</ListGroupStyle>
								)}
							</ListGroupItem>
						</>
					))}
				</ListGroupStyle>
			</div>
			<ListGroupStyle>
				<div className="mb-3">
					<div className="d-flex justify-content-center mb-2">
						<FooterText className="mr-2">Powered by</FooterText>
						<img
							className="d-flex align-self-start"
							src={`${process.env.PUBLIC_URL}/images/TVSDigital_logo_72x26.svg`}
							alt="TVS Digital"
						/>
					</div>
				</div>
			</ListGroupStyle>
		</SidebarComponent>
	)
}
const SidebarComponent = styled.div`
	box-shadow: 0;
	background-color: #cccccc;
	width: 200px;
	position: fixed;
	height: calc(100vh - 50px);
`
const ListGroupStyle = styled(ListGroup)`
	margin-top: 13px;
	.list-group {
		margin: 0;
	}
	.list-group-item {
		background-color: transparent;
		padding: 0;
		border-top: none;
		border-right: none;
		border-bottom: none;
		border-left: 1px solid transparent;
		margin-left: -1px;
		.btn {
			font-weight: 500;
			color: #333333;
			padding-left: 5px;
		}
		&.sub {
			padding-left: 27px;
			.btn {
				font-size: 14px;
				font-weight: 500;
				color: #333333;
			}
			&.is-active {
				a,
				.btn {
					font-size: 14px;
					font-weight: 500;
					color: #333333;
				}
				border-radius: 0px 32px 32px 0px;
				background-color: #e4e4e4;
				margin-right: 8px;
			}
		}
		> div {
			padding: 8px 0px 8px 15px;
			font-weight: 500;
			color: #333333;
			align-self: center;
		}
		&.parent.is-active {
			> div {
				border-radius: 0px 32px 32px 0px;
				background-color: #e4e4e4;
				margin-right: 8px;
			}
		}
	}
`
const FooterText = styled.div`
	font-weight: 400;
	font-size: 10px;
	line-height: 14px;
	margin-right: 5px;
	color: #333333;
`
export default Sidebar
