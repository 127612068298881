/* eslint-disable react/require-default-props */
import React, { CSSProperties, ReactElement } from 'react';
import DatePicker from 'react-datepicker';
import { Label, FormGroup } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import styles from 'styled-components';

interface Props {
  id: string;
  label?: string;
  labelStyle?: CSSProperties;
  inputIcon?: ReactElement;
  placeholder?: string;
  disabled?: boolean;
  maxDate?: any;
  minDate?: any;
  onChange: any;
  selected?: any;
}

const TvsDatepickerElement = ({
  id,
  label,
  labelStyle,
  inputIcon,
  placeholder,
  disabled,
  maxDate,
  minDate,
  onChange,
  selected,
}: Props) => {
  const datePicker = (
    <DatePickerStyle
      onChange={(date) => onChange(date)}
      id={id}
      maxDate={maxDate}
      minDate={minDate}
      selected={selected}
      dateFormat="dd/MM/y"
      placeholderText={placeholder || 'Select Date'}
      autoComplete="off"
    />
  );

  return (
    <FormGroup className="position-relative" style={{ marginBottom: '0em' }}>
      {label && <Label style={labelStyle}>{label}</Label>}
      <Div className={disabled ? 'disabled' : ''}>
        { datePicker }
      </Div>
      <Icon>
        <img src={`${process.env.PUBLIC_URL}/images/calendar.png`} alt="calendar" width="16" height="18" />
      </Icon>
    </FormGroup>
  );
};

const Div = styles.div`
  &.disabled {
    position: relative;
    &:after {
      content: 'Select Date';
      clear: both;
      padding: 13px 10px;
      display: flex;
      position: absolute;
      inset: 0 0 0 0;
      background: #e5e3e3;
      border: 1px solid #ced4da;
    }
  }
`;
const DatePickerStyle = styles(DatePicker)`
  height: 50px;
  border: 1px solid #ced4da;
  width: 100%;
  padding: 10px;
  border-radius: 2px;
`;

const Icon = styles.div`
  position: absolute;
  top: 12px;
  bottom: 0;
  right: 10px;
`;
export default TvsDatepickerElement;
