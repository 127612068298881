import {
  GET_RESULT_COUNT,
  GET_RESULT_COUNT_SUCCESS,
  GET_RESULT_COUNT_FAIL,
  GET_RESULT_LIST,
  GET_RESULT_LIST_SUCCESS,
  GET_RESULT_LIST_FAIL,
} from "../actions/types";

const initialState = {
  resultCount: 0,
  numOfCallbacks: 0,
  resultList: [],
  loading: false,
  error: null,
};

export default function resultReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_RESULT_COUNT:
      return {
        ...state,
        resultCount: 0,
        numOfCallbacks: 0,
        loading: true,
        error: null,
      };
    case GET_RESULT_COUNT_SUCCESS:
      return {
        ...state,
        resultCount: payload.resultCount,
        numOfCallbacks: payload.numOfCallbacks,
        loading: false,
        error: null,
      };
    case GET_RESULT_COUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case GET_RESULT_LIST:
      return {
        ...state,
        resultList: [],
        loading: true,
        error: null,
      };
    case GET_RESULT_LIST_SUCCESS:
      return {
        ...state,
        resultList: payload.resultList,
        loading: false,
        error: null,
      };
    case GET_RESULT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
}
