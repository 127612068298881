import {
  SUBMIT_FEEDBACK,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAIL,
} from "../actions/types";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export default function feedbackReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SUBMIT_FEEDBACK:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
        error: null,
      };
    case SUBMIT_FEEDBACK_FAIL:
      return {
        ...state,
        data: null,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
}
