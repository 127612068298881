import React, { useEffect, useState } from "react";
import "./App.css";
import Login from "./containers/Login/login";
import Dedupe from "./containers/Dedupe/dedupe";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import CustomerDetail from "containers/Customer/detail";
import PrivateRoute from "components/routers/PrivateRoute";
import ReduxToastr from "react-redux-toastr";
import AppContext from "./helpers/context";
import store from "./store.js";
import { Provider } from "react-redux";
import ScrollToTop from "./containers/ScrollToTop";
import { INITIAL_FORM_VALUES } from "constant";
import { ZendeskAPI } from "react-zendesk";

function App() {
  const [submitted, setSubmitted] = useState(false);
  const [showOptionalField, setShowOptionalField] = useState(false);
  const [searchFields, setSearchFields] = useState(INITIAL_FORM_VALUES);
  const [lastSearchedAt, setLastSearchedAt] = useState("");
  const [searchCompleted, setSearchCompleted] = useState(true);
  const [resultCount, setResultCount] = useState(0);
  const [resultList, setResultList] = useState([]);

  useEffect(() => {
    ZendeskAPI("webWidget", "hide");
    ZendeskAPI("webWidget:on", "close", () => {
      ZendeskAPI("webWidget", "hide");
    });
    ZendeskAPI("webWidget:on", "chat:popout", () => {
      ZendeskAPI("webWidget", "hide");
    });
  }, []);

  const iconHelpdesk = `${process.env.PUBLIC_URL}/images/icon_helpdesk.svg`;

  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppContext.Provider
          value={{
            submitted,
            setSubmitted,
            showOptionalField,
            setShowOptionalField,
            searchFields,
            setSearchFields,
            lastSearchedAt,
            setLastSearchedAt,
            searchCompleted,
            setSearchCompleted,
            resultCount,
            setResultCount,
            resultList,
            setResultList,
          }}
        >
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/search" component={Dedupe} />
            <PrivateRoute exact path="/customer" component={CustomerDetail} />
            <Route path="/" component={Login} />
          </Switch>
          <ReduxToastr
            timeOut={3000}
            newestOnTop={false}
            preventDuplicates
            position="top-left"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick={true}
          />
        </AppContext.Provider>
      </BrowserRouter>
      <img
        src={iconHelpdesk}
        alt="helpdesk"
        style={{
          position: "fixed",
          right: "10px",
          bottom: "6px",
          cursor: "pointer",
        }}
        onClick={() => {
          ZendeskAPI("webWidget", "open");
          ZendeskAPI("webWidget", "show");
        }}
      />
    </Provider>
  );
}

export default App;
