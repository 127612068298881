export const PHONE_VALIDATE = [
  {
    value: "+65",
    validate: {
      length: 8,
    },
  },
  {
    value: "+63",
    validate: {
      length: 10,
    },
  },
  {
    value: "+977",
    validate: {
      length: 10,
    },
  },
  {
    value: "+91",
    validate: {
      length: 10,
    },
  },
];

export function isNotExist(val) {
  if (val === null || val === undefined) {
    return true;
  }
  return false;
}

export function isExist(val) {
  if (val === null || val === undefined) {
    return false;
  }
  return true;
}
