import { Link } from 'react-router-dom';

type Item = {
  label: string;
  link?: string;
};

interface Props {
  data: Item[];
}

const Breadcrumb = ({ data }: Props) => {
  return (
    <div>
      <div>
        <ul className="d-flex py-2 align-items-center tvs-breadcrumb">
          {data.map((item: Item) => (
            <li className="mr-2" key={item.label}>
              {item?.link ? (
                <Link to={item.link} className="text-small" style={{ textTransform: 'capitalize', textDecoration: 'none' }}>
                  {item.label}
                </Link>
              ) : (
                <div className="text-small">{item.label}</div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Breadcrumb;
