import { FormGroup } from "reactstrap";
import { Field } from "formik";
import styles from "styled-components";
import SelectDropdown from "components/elements/SelectDropDown";
import TvsDatepickerElement from "components/elements/DatepickerElement";
import { MOBILE_COUNTRY_CODE } from "helpers/countrycode";
import CountryCodeDropDown from "components/elements/CountryCodeDropDown";
import { cities } from "constant/cities";
import { MINIMUM_AGE, MAXIMUM_AGE } from "constant";

const RequiredField = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleBlur,
}) => {
  return (
    <FormGroupStyle>
      <div className="first_name">
        <FieldStyle
          name="first_name"
          type="text"
          placeholder="Enter First Name"
          onBlur={(event) => {
            handleBlur(event);
            setFieldValue(event.target.name, event.target.value.trim());
          }}
        />
        {touched.first_name && errors.first_name && (
          <div className="text-danger">{errors.first_name}</div>
        )}
      </div>
      <div className="middle_name">
        <FieldStyle
          name="middle_name"
          type="text"
          placeholder="Enter Middle Name"
          onBlur={(event) => {
            handleBlur(event);
            setFieldValue(event.target.name, event.target.value.trim());
          }}
        />
        {touched.middle_name && errors.middle_name && (
          <div className="text-danger">{errors.middle_name}</div>
        )}
      </div>
      <div className="last_name">
        <FieldStyle
          name="last_name"
          type="text"
          placeholder="Enter Last Name"
          onBlur={(event) => {
            handleBlur(event);
            setFieldValue(event.target.name, event.target.value.trim());
          }}
        />
        {touched.last_name && errors.last_name && (
          <div className="text-danger">{errors.last_name}</div>
        )}
      </div>
      <ContactStyle className="contact">
        <div className="country_code">
          <FieldStyle
            component={CountryCodeDropDown}
            name="country_code"
            data={MOBILE_COUNTRY_CODE}
            handleChange={(e: any) => setFieldValue("country_code", e)}
            value={values.country_code}
          />
          {touched.country_code && errors.country_code && (
            <div className="text-danger">{errors.country_code}</div>
          )}
        </div>
        <div className="phone_number">
          <FieldStyle
            name="phone_number"
            type="text"
            placeholder="Enter Phone Number"
          />
          {touched.phone_number && errors.phone_number && (
            <div className="text-danger">{errors.phone_number}</div>
          )}
        </div>
      </ContactStyle>
      <div className="dob">
        <FieldStyle
          component={TvsDatepickerElement}
          id="date_of_birth"
          name="date_of_birth"
          minDate={calculateMinDOB()}
          maxDate={calculateMaxDOB()}
          onChange={(date) =>
            setFieldValue("date_of_birth", date)
          }
          placeholder={"Enter Date of Birth"}
          value={values.date_of_birth}
          selected={values.date_of_birth}
        />
        {touched.date_of_birth && errors.date_of_birth && (
          <div className="mt-1 text-danger">{errors.date_of_birth}</div>
        )}
      </div>
      <div className="city">
        <FieldStyle
          component={SelectDropdown}
          name="city"
          data={cities}
          handleChange={(e: any) => setFieldValue("city", e)}
          value={values.city}
          initActive="Select City"
          id="city-dropdown"
        />
        {touched.city && errors.city && (
          <div className="mt-1 text-danger">{errors.city}</div>
        )}
      </div>
      <div className="zip">
        <FieldStyle
          name="zipcode"
          type="text"
          placeholder="Enter Zip Code"
          onBlur={(event) => {
            handleBlur(event);
            setFieldValue(event.target.name, event.target.value.trim());
          }}
        />
        {touched.zipcode && errors.zipcode && (
          <div className="text-danger">{errors.zipcode}</div>
        )}
      </div>
    </FormGroupStyle>
  );
};

const calculateMinDOB = () => {
  const minDOB = new Date();
  minDOB.setUTCFullYear(minDOB.getUTCFullYear() - MAXIMUM_AGE);

  return minDOB;
}

const calculateMaxDOB = () => {
  const maxDOB = new Date();
  maxDOB.setUTCFullYear(maxDOB.getUTCFullYear() - MINIMUM_AGE);

  return maxDOB;
}

const FormGroupStyle = styles(FormGroup)`
	height: auto;
	padding-top: 10px;
	padding-bottom: 30px;
	border-bottom: solid;
	border-width: thin;
	border-color: #d8daff !important;
	display: grid; 
	grid-auto-columns: 1fr; 
	grid-template-columns: 1fr 1fr 1fr; 
	gap: 25px 30px;
	grid-template-areas: 
	"first_name middle_name last_name"
	"contact dob city"
	"zip empty empty"; 
`;
const FieldStyle = styles(Field)`
	height: 55px;
	padding: 10px;
	width: 100%;
	border-width: thin;
	border-style: solid;
	margin-bottom: 5px;
	border-radius: 10px;
`;
const ContactStyle = styles.div`
	display: grid; 
	grid-auto-columns: 1fr; 
	grid-template-columns: 1fr 5fr; 
	grid-template-rows: 1fr; 
	gap: 0px 10px; 
	grid-template-areas: 
	  "country_code phone_number"; 
	grid-area: contact; 
`;

export default RequiredField;
