export const branches = [
  {
    code: "BBA1",
    name: "Motomura (MB)",
  },
  {
    code: "BBK1",
    name: "Buendia Kawasaki BB",
  },
  {
    code: "BBM1",
    name: "Buendia KTM BB",
  },
  {
    code: "BBS1",
    name: "Buendia Suzuki BB",
  },
  {
    code: "BCAK1",
    name: "Calamba Kawasaki BB",
  },
  {
    code: "BCAM1",
    name: "Calamba KTM BB",
  },
  {
    code: "BCH1",
    name: "Cabanatuan Honda BB",
  },
  {
    code: "BCK1",
    name: "Cabanatuan Kawasaki BB",
  },
  {
    code: "BCM1",
    name: "Cabanatuan KTM BB",
  },
  {
    code: "BCS1",
    name: "Cabanatuan Suzuki BB",
  },
  {
    code: "BDM1",
    name: "Dasmarinas KTM BB",
  },
  {
    code: "BDUC1",
    name: "Ducati Cebu",
  },
  {
    code: "BHDC1",
    name: "Harley-Davidson of Cavite",
  },
  {
    code: "BIK1",
    name: "Imus Kawasaki BB",
  },
  {
    code: "BMK1",
    name: "Matina Kawasaki BB",
  },
  {
    code: "BMS1",
    name: "Matina Suzuki BB",
  },
  {
    code: "BPK1",
    name: "Calasiao Kawasaki BB",
  },
  {
    code: "BPS1",
    name: "Calasiao Suzuki BB",
  },
  {
    code: "BRK1",
    name: "Cainta Kawasaki BB",
  },
  {
    code: "BSK1",
    name: "San Fernando Kawasaki BB",
  },
  {
    code: "BSM1",
    name: "San Fernando KTM BB",
  },
  {
    code: "BSS1",
    name: "San Fernando Suzuki BB",
  },
  {
    code: "BTY1",
    name: "Timog Avenue Yamaha BB",
  },
  {
    code: "BTYK1",
    name: "Taytay Kawasaki BB",
  },
  {
    code: "BVK1",
    name: "Vigan Kawasaki BB",
  },
  {
    code: "BVM1",
    name: "Vigan KTM BB",
  },
  {
    code: "HAA1",
    name: "H-Aliaga",
  },
  {
    code: "HAO1",
    name: "H-Antipolo",
  },
  {
    code: "HAT1",
    name: "H-Apalit",
  },
  {
    code: "HBA1",
    name: "H-Buendia",
  },
  {
    code: "HBE1",
    name: "H-Bocaue",
  },
  {
    code: "HBL1",
    name: "H-Binan",
  },
  {
    code: "HBN1",
    name: "H-Binalonan",
  },
  {
    code: "HBO1",
    name: "H-Bongabon",
  },
  {
    code: "HBPN1",
    name: "H-Pulilan",
  },
  {
    code: "HCA1",
    name: "H-Calamba",
  },
  {
    code: "HCN1",
    name: "H-Cabanatuan",
  },
  {
    code: "HCN2",
    name: "H-Nabao Cab",
  },
  {
    code: "HCO1",
    name: "H-Cabiao",
  },
  {
    code: "HCP1",
    name: "H-Cuyapo",
  },
  {
    code: "HDO1",
    name: "H-Davao",
  },
  {
    code: "HDS1",
    name: "H-Dasmarinas",
  },
  {
    code: "HGE1",
    name: "H-Cebu",
  },
  {
    code: "HGO1",
    name: "H-Guiguinto",
  },
  {
    code: "HISB1",
    name: "H-Sta. Barbara",
  },
  {
    code: "HJN1",
    name: "H-Jaen",
  },
  {
    code: "HLU1",
    name: "H-La Union",
  },
  {
    code: "HMA1",
    name: "H-Minglanilla",
  },
  {
    code: "HMOL1",
    name: "H-Opol",
  },
  {
    code: "HMS1",
    name: "H-Malolos",
  },
  {
    code: "HMZ1",
    name: "H-Munoz",
  },
  {
    code: "HNPN1",
    name: "H-Palayan",
  },
  {
    code: "HNSI1",
    name: "H-San Isidro",
  },
  {
    code: "HO",
    name: "Head Office",
  },
  {
    code: "HOBB",
    name: "Head Office Big Bikes",
  },
  {
    code: "HOO1",
    name: "H-Olongapo",
  },
  {
    code: "HPBN1",
    name: "H-Bugallon",
  },
  {
    code: "HSF1",
    name: "H-San Fernando",
  },
  {
    code: "HSO1",
    name: "H-Santiago",
  },
  {
    code: "HSP1",
    name: "H-San Pedro",
  },
  {
    code: "HTO1",
    name: "H-Tuguegarao",
  },
  {
    code: "HTY1",
    name: "H-Talisay",
  },
  {
    code: "KAS1",
    name: "K-Angeles",
  },
  {
    code: "L3S1",
    name: "3S Shop",
  },
  {
    code: "LAA1",
    name: "Aliaga",
  },
  {
    code: "LAI1",
    name: "Alicia",
  },
  {
    code: "LAN1",
    name: "Alcala",
  },
  {
    code: "LAP1",
    name: "Arayat",
  },
  {
    code: "LAS1",
    name: "Angeles",
  },
  {
    code: "LAS2",
    name: "Angeles 2",
  },
  {
    code: "LAT1",
    name: "Apalit",
  },
  {
    code: "LAY1",
    name: "Aringay",
  },
  {
    code: "LBA1",
    name: "Balanga",
  },
  {
    code: "LBAT1",
    name: "Angat",
  },
  {
    code: "LBC1",
    name: "Baloc",
  },
  {
    code: "LBD1",
    name: "Bustos",
  },
  {
    code: "LBE1",
    name: "Bocaue",
  },
  {
    code: "LBG1",
    name: "Baliuag",
  },
  {
    code: "LBM1",
    name: "Bambang",
  },
  {
    code: "LBN1",
    name: "Bayambang",
  },
  {
    code: "LBP1",
    name: "Binalonan",
  },
  {
    code: "LBPG1",
    name: "Paombong",
  },
  {
    code: "LBS1",
    name: "Balagtas",
  },
  {
    code: "LBSR1",
    name: "San Rafael",
  },
  {
    code: "LBT1",
    name: "Bautista",
  },
  {
    code: "LBU1",
    name: "Bugallon",
  },
  {
    code: "LCG1",
    name: "Camiling",
  },
  {
    code: "LCK1",
    name: "Kawasaki Cabanatuan",
  },
  {
    code: "LCL1",
    name: "Calasiao",
  },
  {
    code: "LCN1",
    name: "Suzuki Cabanatuan",
  },
  {
    code: "LCO1",
    name: "Cabiao",
  },
  {
    code: "LCP1",
    name: "Cuyapo",
  },
  {
    code: "LCS1",
    name: "Capas",
  },
  {
    code: "LCT1",
    name: "Concepcion",
  },
  {
    code: "LDN1",
    name: "Dinalupihan",
  },
  {
    code: "LFA1",
    name: "Floridablanca",
  },
  {
    code: "LGA1",
    name: "Guagua",
  },
  {
    code: "LGD1",
    name: "Gabaldon",
  },
  {
    code: "LGN1",
    name: "W-Gapan",
  },
  {
    code: "LHY1",
    name: "Hagonoy",
  },
  {
    code: "LIN1",
    name: "Ilagan",
  },
  {
    code: "LJN1",
    name: "Jaen",
  },
  {
    code: "LLA1",
    name: "Llanera",
  },
  {
    code: "LLN1",
    name: "Lupao",
  },
  {
    code: "LLO1",
    name: "Lubao",
  },
  {
    code: "LLR1",
    name: "Laur",
  },
  {
    code: "LLY1",
    name: "Limay",
  },
  {
    code: "LLZ1",
    name: "La Paz",
  },
  {
    code: "LMA1",
    name: "Moncada",
  },
  {
    code: "LMB1",
    name: "Muzon",
  },
  {
    code: "LMG1",
    name: "Magalang",
  },
  {
    code: "LMI1",
    name: "Malasiqui",
  },
  {
    code: "LMM1",
    name: "Mangatarem",
  },
  {
    code: "LMN1",
    name: "W-Main",
  },
  {
    code: "LMO1",
    name: "Marilao",
  },
  {
    code: "LMS1",
    name: "Malolos",
  },
  {
    code: "LMX1",
    name: "Mexico",
  },
  {
    code: "LMY1",
    name: "Meycauayan",
  },
  {
    code: "LMY2",
    name: "Meycauayan 2",
  },
  {
    code: "LMZ1",
    name: "Munoz",
  },
  {
    code: "LNGT1",
    name: "General Tinio",
  },
  {
    code: "LNMZ2",
    name: "Munoz 2",
  },
  {
    code: "LNPN2",
    name: "Palayan 2",
  },
  {
    code: "LNS1",
    name: "Novaliches",
  },
  {
    code: "LNSI2",
    name: "San Isidro 2",
  },
  {
    code: "LOI1",
    name: "Orani",
  },
  {
    code: "LOO1",
    name: "Olongapo",
  },
  {
    code: "LPA1",
    name: "Penaranda",
  },
  {
    code: "LPC1",
    name: "Porac",
  },
  {
    code: "LPI1",
    name: "Paniqui",
  },
  {
    code: "LPL1",
    name: "Plaridel",
  },
  {
    code: "LPN1",
    name: "Pulilan",
  },
  {
    code: "LPSA1",
    name: "Santa Ana Pampanga",
  },
  {
    code: "LPSF1",
    name: "San Fabian",
  },
  {
    code: "LPSL1",
    name: "Sual",
  },
  {
    code: "LPY1",
    name: "Palayan",
  },
  {
    code: "LRL1",
    name: "Rizal",
  },
  {
    code: "LRO1",
    name: "Rosario L.U.",
  },
  {
    code: "LRS1",
    name: "Rosales",
  },
  {
    code: "LSA1",
    name: "San Antonio",
  },
  {
    code: "LSB1",
    name: "San Miguel",
  },
  {
    code: "LSC1",
    name: "Subic",
  },
  {
    code: "LSD1",
    name: "Sto. Domingo NE",
  },
  {
    code: "LSD2",
    name: "Santo Domingo Ilocos Sur",
  },
  {
    code: "LSE1",
    name: "San Jose Del Monte",
  },
  {
    code: "LSF1",
    name: "San Fernando",
  },
  {
    code: "LSG1",
    name: "San Ildefonso",
  },
  {
    code: "LSI1",
    name: "San Mateo",
  },
  {
    code: "LSJ1",
    name: "San Jose",
  },
  {
    code: "LSK1",
    name: "San Fernando KTM",
  },
  {
    code: "LSL1",
    name: "San Leonardo",
  },
  {
    code: "LSM1",
    name: "Sta. Maria",
  },
  {
    code: "LSN1",
    name: "San Isidro",
  },
  {
    code: "LSO1",
    name: "Santiago",
  },
  {
    code: "LSP1",
    name: "Santa Barbara",
  },
  {
    code: "LSS1",
    name: "Sison",
  },
  {
    code: "LST1",
    name: "Sta. Ignacia",
  },
  {
    code: "LTA1",
    name: "Tungkong Mangga",
  },
  {
    code: "LTC2",
    name: "Tarlac",
  },
  {
    code: "LTG1",
    name: "TVS Guimba",
  },
  {
    code: "LTI1",
    name: "Tumauini",
  },
  {
    code: "LTN1",
    name: "Tagudin",
  },
  {
    code: "LVS1",
    name: "Villasis",
  },
  {
    code: "LVT1",
    name: "Victoria",
  },
  {
    code: "LWS1",
    name: "WBS - WAREHOUSE",
  },
  {
    code: "LYA1",
    name: "Yamaha Apalit",
  },
  {
    code: "LYB1",
    name: "Yamaha Norzagaray",
  },
  {
    code: "LYC1",
    name: "Yamaha Cabiao",
  },
  {
    code: "LYG1",
    name: "Yamaha Sta.Ignacia",
  },
  {
    code: "LYH1",
    name: "Yamaha Hagonoy",
  },
  {
    code: "LYI1",
    name: "Yamaha Candon",
  },
  {
    code: "LYL1",
    name: "Yamaha Naguilian",
  },
  {
    code: "LYM1",
    name: "Yamaha Mangaldan",
  },
  {
    code: "LYN1",
    name: "Yamaha-Nova",
  },
  {
    code: "LYO1",
    name: "Yamaha Orani",
  },
  {
    code: "LYP1",
    name: "Yamaha Malasiqui",
  },
  {
    code: "LYR1",
    name: "Yamaha Rizal",
  },
  {
    code: "LYS1",
    name: "Yamaha Sta. Rosa",
  },
  {
    code: "LYZ1",
    name: "Yamaha Olongapo",
  },
  {
    code: "MAN1",
    name: "Apokon",
  },
  {
    code: "MBA1",
    name: "Bajada",
  },
  {
    code: "MCO1",
    name: "Cagayan De Oro",
  },
  {
    code: "MCO2",
    name: "Cagayan De Oro2",
  },
  {
    code: "MCO3",
    name: "Cagayan De Oro 3",
  },
  {
    code: "MDC1",
    name: "Don Carlos",
  },
  {
    code: "MDS1",
    name: "Digos",
  },
  {
    code: "MDY1",
    name: "Dalisay",
  },
  {
    code: "MER1",
    name: "El Salvador",
  },
  {
    code: "MMA1",
    name: "Maa",
  },
  {
    code: "MMD1",
    name: "Matina",
  },
  {
    code: "MMG1",
    name: "Maramag",
  },
  {
    code: "MMO1",
    name: "Manolo Fortich",
  },
  {
    code: "MMY1",
    name: "Malaybalay",
  },
  {
    code: "MPO1",
    name: "Panabo",
  },
  {
    code: "MQN1",
    name: "Quezon",
  },
  {
    code: "MTL1",
    name: "Toril",
  },
  {
    code: "MTM1",
    name: "Tagum",
  },
  {
    code: "MTO1",
    name: "Tibungco",
  },
  {
    code: "MVA1",
    name: "Valencia",
  },
  {
    code: "PAO1",
    name: "Agoo",
  },
  {
    code: "PBC1",
    name: "Batac",
  },
  {
    code: "PBG1",
    name: "Bantug",
  },
  {
    code: "PBN1",
    name: "Bongabon",
  },
  {
    code: "PCI1",
    name: "Candon",
  },
  {
    code: "PCN1",
    name: "Cauayan",
  },
  {
    code: "PDN1",
    name: "Dagupan",
  },
  {
    code: "PGA1",
    name: "Guimba",
  },
  {
    code: "PGN1",
    name: "P-Gapan",
  },
  {
    code: "PKI1",
    name: "Kawasaki",
  },
  {
    code: "PKI2",
    name: "Kawasaki 2 - Spareparts",
  },
  {
    code: "PLG1",
    name: "Laoag",
  },
  {
    code: "PLN1",
    name: "Lingayen",
  },
  {
    code: "PLU1",
    name: "La Union",
  },
  {
    code: "PMG1",
    name: "Manaoag",
  },
  {
    code: "PMN1",
    name: "P-Main",
  },
  {
    code: "PRS1",
    name: "Roxas",
  },
  {
    code: "PSC1",
    name: "San Carlos",
  },
  {
    code: "PSO1",
    name: "Solano",
  },
  {
    code: "PSR1",
    name: "Sta. Rosa N.E.",
  },
  {
    code: "PTA1",
    name: "Talavera",
  },
  {
    code: "PTB1",
    name: "Tuguegarao 3",
  },
  {
    code: "PUA1",
    name: "Urdaneta",
  },
  {
    code: "PUA2",
    name: "Urdaneta 2",
  },
  {
    code: "PVN1",
    name: "Vigan",
  },
  {
    code: "PZA1",
    name: "Zaragosa",
  },
  {
    code: "RAA1",
    name: "Araneta",
  },
  {
    code: "RAO1",
    name: "Antipolo",
  },
  {
    code: "RAS1",
    name: "Abad Santos",
  },
  {
    code: "RBA1",
    name: "Buendia",
  },
  {
    code: "RBI1",
    name: "Binangonan",
  },
  {
    code: "RBN1",
    name: "Binan",
  },
  {
    code: "RBR1",
    name: "Bacoor",
  },
  {
    code: "RBS1",
    name: "Batangas",
  },
  {
    code: "RBT1",
    name: "Balayan",
  },
  {
    code: "RCA1",
    name: "Calamba",
  },
  {
    code: "RCB1",
    name: "Cogeo",
  },
  {
    code: "RCBS1",
    name: "Caloocan",
  },
  {
    code: "RCM1",
    name: "Cembo",
  },
  {
    code: "RCO1",
    name: "Cubao",
  },
  {
    code: "RCR1",
    name: "Cainta",
  },
  {
    code: "RDS1",
    name: "Dasmarinas",
  },
  {
    code: "REA1",
    name: "Espana",
  },
  {
    code: "RIS1",
    name: "Imus",
  },
  {
    code: "RKT1",
    name: "Kawit",
  },
  {
    code: "RLA1",
    name: "Lipa",
  },
  {
    code: "RLB1",
    name: "Los Banos",
  },
  {
    code: "RLP1",
    name: "Las Pinas",
  },
  {
    code: "RLSR2",
    name: "R-Sta. Rosa 2",
  },
  {
    code: "RLSR3",
    name: "Sta. Rosa - Piaggio",
  },
  {
    code: "RLY1",
    name: "Lemery",
  },
  {
    code: "RMA1",
    name: "Marikina",
  },
  {
    code: "RMER1",
    name: "E. Rodriguez",
  },
  {
    code: "RMJR1",
    name: "JP Rizal",
  },
  {
    code: "RNC1",
    name: "Naic",
  },
  {
    code: "RPG1",
    name: "Pasig",
  },
  {
    code: "RPPP1",
    name: "Puerto Princesa",
  },
  {
    code: "RPS1",
    name: "Pateros",
  },
  {
    code: "RRB1",
    name: "Rosario Batangas",
  },
  {
    code: "RSA1",
    name: "Santa Ana Manila",
  },
  {
    code: "RSB1",
    name: "Shaw Blvd.",
  },
  {
    code: "RSC1",
    name: "Sta. Cruz",
  },
  {
    code: "RSN1",
    name: "Sangandaan",
  },
  {
    code: "RSO1",
    name: "San Pablo",
  },
  {
    code: "RSP1",
    name: "San Pedro",
  },
  {
    code: "RSR1",
    name: "R-Sta. Rosa",
  },
  {
    code: "RST1",
    name: "Sucat",
  },
  {
    code: "RTA1",
    name: "Tanza",
  },
  {
    code: "RTG1",
    name: "Tiaong",
  },
  {
    code: "RTM1",
    name: "Trece Martires",
  },
  {
    code: "RTY1",
    name: "Taytay",
  },
  {
    code: "SCLU1",
    name: "Suzuki Lapu-Lapu",
  },
  {
    code: "SCO1",
    name: "Suzuki Calasiao",
  },
  {
    code: "SSF1",
    name: "Suzuki San Fernando",
  },
  {
    code: "TBG1",
    name: "TVS Bantug",
  },
  {
    code: "TFA1",
    name: "TVS Floridablanca",
  },
  {
    code: "TGA1",
    name: "TVS Guimba-inactive",
  },
  {
    code: "TLCA1",
    name: "TVS Calamba",
  },
  {
    code: "TNRL1",
    name: "TVS Rizal",
  },
  {
    code: "TPI1",
    name: "TVS Paniqui",
  },
  {
    code: "TSE1",
    name: "TVS San Jose del Monte",
  },
  {
    code: "TSF1",
    name: "TVS San Fernando",
  },
  {
    code: "TTA1",
    name: "TVS Talavera",
  },
  {
    code: "TTN1",
    name: "TVS Talibon",
  },
  {
    code: "VBD1",
    name: "Bacolod merge",
  },
  {
    code: "VBD2",
    name: "Bacolod 2",
  },
  {
    code: "VBD3",
    name: "Bacolod 3",
  },
  {
    code: "VBN1",
    name: "Balasan",
  },
  {
    code: "VBO1",
    name: "Bogo",
  },
  {
    code: "VBS1",
    name: "Bais",
  },
  {
    code: "VBTD1",
    name: "Trinidad",
  },
  {
    code: "VBVA1",
    name: "Valencia Bohol",
  },
  {
    code: "VCLU2",
    name: "Basak Lapulapu",
  },
  {
    code: "VCMN1",
    name: "Marigondon",
  },
  {
    code: "VCN1",
    name: "Carmen",
  },
  {
    code: "VCN2",
    name: "Carmen Bohol",
  },
  {
    code: "VCS1",
    name: "Cortes",
  },
  {
    code: "VCU1",
    name: "Guadalupe",
  },
  {
    code: "VCZ1",
    name: "Cadiz",
  },
  {
    code: "VDE1",
    name: "Dumaguete",
  },
  {
    code: "VEA1",
    name: "Estancia",
  },
  {
    code: "VFE1",
    name: "Fuente Osmena",
  },
  {
    code: "VGN1",
    name: "Guindulman",
  },
  {
    code: "VIMO1",
    name: "Miag-ao",
  },
  {
    code: "VIO1",
    name: "Legacy code",
  },
  {
    code: "VIO2",
    name: "Ilo-Ilo 2",
  },
  {
    code: "VIW1",
    name: "Ilo-Ilo Warehouse",
  },
  {
    code: "VJA1",
    name: "Jagna",
  },
  {
    code: "VJI1",
    name: "Jaro Ilo-Ilo",
  },
  {
    code: "VLB1",
    name: "Loon",
  },
  {
    code: "VLI1",
    name: "Liloan",
  },
  {
    code: "VLI2",
    name: "Liloan 2",
  },
  {
    code: "VLN1",
    name: "Labogon",
  },
  {
    code: "VLU1",
    name: "Lapulapu",
  },
  {
    code: "VLY1",
    name: "Loay",
  },
  {
    code: "VMA2",
    name: "Minglanilla 2",
  },
  {
    code: "VMC1",
    name: "Minglanilla",
  },
  {
    code: "VNA1",
    name: "Naga",
  },
  {
    code: "VON1",
    name: "Oton",
  },
  {
    code: "VPI1",
    name: "Passi",
  },
  {
    code: "VPO1",
    name: "Pardo",
  },
  {
    code: "VRS1",
    name: "Roxas City",
  },
  {
    code: "VSA1",
    name: "Santa Barbara Iloilo",
  },
  {
    code: "VSY1",
    name: "Silay",
  },
  {
    code: "VTB1",
    name: "Talibon",
  },
  {
    code: "VTB2",
    name: "Talibon 2",
  },
  {
    code: "VTG1",
    name: "Tubigon",
  },
  {
    code: "VTN1",
    name: "Tagbilaran",
  },
  {
    code: "VTN2",
    name: "Tagbilaran 2",
  },
  {
    code: "VTY1",
    name: "Talisay",
  },
  {
    code: "VUY1",
    name: "Ubay",
  },
  {
    code: "VWH1",
    name: "Visayas Warehouse",
  },
  {
    code: "VYC1",
    name: "Yamaha Carmen",
  },
  {
    code: "VYS1",
    name: "Yamaha Silay",
  },
  {
    code: "WBA1",
    name: "Wheeltek - Bodega",
  },
  {
    code: "WBM1",
    name: "Warehouse Buendia-Marconi",
  },
  {
    code: "WBS",
    name: "Wheeltek WBS - Spareparts",
  },
  {
    code: "WBVA1",
    name: "Warehouse Valencia",
  },
  {
    code: "WCB1",
    name: "CABANATUAN CITY - BITAS",
  },
  {
    code: "WDTM1",
    name: "Warehouse Tagum",
  },
  {
    code: "WIO1",
    name: "Warehouse Ilo-Ilo",
  },
  {
    code: "WLH1",
    name: "Warehouse Lapulapu Honda",
  },
  {
    code: "WLU1",
    name: "Warehouse Lapu-Lapu",
  },
  {
    code: "WMD1",
    name: "Warehouse Matina",
  },
  {
    code: "WMH1",
    name: "Warehouse Matina Honda",
  },
  {
    code: "WPC1",
    name: "PALAYAN CITY - WAREHOUSE",
  },
  {
    code: "WRC1",
    name: "Sta. Rosa Recon Warehouse",
  },
  {
    code: "WSR1",
    name: "STA ROSA - BRAND NEW Wheeltek Warehouse",
  },
  {
    code: "WSR2",
    name: "STA ROSA MAPALAD - WAREHOUSE",
  },
  {
    code: "WSR3",
    name: "Sta Rosa Secondhand Warehouse",
  },
  {
    code: "WSR4",
    name: "Sta. Rosa Brand New Motorista Warehouse",
  },
  {
    code: "YAA1",
    name: "Yamaha Aliaga",
  },
  {
    code: "YAN1",
    name: "Yamaha Asingan",
  },
  {
    code: "YAP1",
    name: "Yamaha Arayat",
  },
  {
    code: "YAT1",
    name: "Yamaha Apalit-inactive",
  },
  {
    code: "YBAE1",
    name: "Yamaha Alae",
  },
  {
    code: "YBC1",
    name: "Yamaha Cabanatuan",
  },
  {
    code: "YBLN1",
    name: "Yamaha Loon",
  },
  {
    code: "YBMS1",
    name: "Yamaha Malolos",
  },
  {
    code: "YBN1",
    name: "Yamaha Bacnotan",
  },
  {
    code: "YBS1",
    name: "Yamaha Bustos",
  },
  {
    code: "YCMN1",
    name: "Yamaha Marigondon",
  },
  {
    code: "YCN1",
    name: "Yamaha Candon-inactive",
  },
  {
    code: "YCO1",
    name: "Yamaha Cabiao-inactive",
  },
  {
    code: "YDKG1",
    name: "Yamaha Kapalong",
  },
  {
    code: "YEE1",
    name: "Yamaha Echague",
  },
  {
    code: "YHY1",
    name: "Yamaha Hagonoy-inactive",
  },
  {
    code: "YLY1",
    name: "Yamaha Limay",
  },
  {
    code: "YMEA1",
    name: "Yamaha Espana",
  },
  {
    code: "YMG1",
    name: "Yamaha Magalang",
  },
  {
    code: "YMN1",
    name: "Yamaha Meycauayan",
  },
  {
    code: "YMO1",
    name: "Yamaha Miag ao",
  },
  {
    code: "YNMZ1",
    name: "Yamaha Munoz",
  },
  {
    code: "YNPN1",
    name: "Yamaha Palayan",
  },
  {
    code: "YNSD1",
    name: "Yamaha Sto. Domingo",
  },
  {
    code: "YON1",
    name: "Yamaha Oton",
  },
  {
    code: "YPG1",
    name: "Yamaha Paombong",
  },
  {
    code: "YPN1",
    name: "Yamaha Pasuquin",
  },
  {
    code: "YRN1",
    name: "Yamaha Ramon",
  },
  {
    code: "YRO1",
    name: "Yamaha Rosario",
  },
  {
    code: "YSL1",
    name: "Yamaha San Leonardo",
  },
  {
    code: "YSN1",
    name: "Yamaha San Isidro",
  },
  {
    code: "YTBT1",
    name: "Yamaha Tarlac",
  },
  {
    code: "YTG1",
    name: "Yamaha Timog Avenue",
  },
  {
    code: "YVS1",
    name: "Yamaha Victorias",
  },
  {
    code: "ZQWH",
    name: "MC - Holding Co.",
  },
  {
    code: "VCLU1",
    name: "W-BASAK LAPULAPU",
  },
];
