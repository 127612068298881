import { combineReducers } from "redux";
import auth from "./auth";
import search from "./search";
import result from "./result";
import feedback from "./feedback";
import { reducer as toastrReducer } from "react-redux-toastr";
export default combineReducers({
  auth,
  search,
  result,
  feedback,
  toastr: toastrReducer,
});
