import { useEffect, useRef, useState } from "react";
import { Localization } from "../../constant/config";
import * as yup from "yup";
import { Formik, FormikProps, Form, Field } from "formik";
import { Button, FormGroup, Label, Card, CardBody } from "reactstrap";
import styles from "styled-components";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import store from "../../store.js";
import { loadUser } from "../../actions/auth";
import { TOASTR_ERROR_OPTIONS } from "../../constant";
import { toastr } from "react-redux-toastr";
import PropTypes from "prop-types";
import { TOKEN_NAME } from "../../constant";
import { isExist } from "helpers/validate";

interface LoginFormValue {
  email: string;
  password: string;
}

const Login = ({ auth, login }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formikRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_NAME);
    if (isExist(token)) {
      store.dispatch(loadUser());
    }
  }, []);

  useEffect(() => {
    if (auth?.isAuthenticated) {
      history.replace("/search");
    }
  }, [auth, history]);

  useEffect(() => {
    if (auth?.error) {
      setIsSubmitting(false);
      toastr.error(
        "Please enter a valid email id or password",
        auth.error,
        TOASTR_ERROR_OPTIONS
      );
    }

    return () => {
      toastr.clean();
    };
  }, [auth]);

  const onSubmit = (values) => {
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
    }, 2000);

    login(values.email, values.password, history);
  };

  const loginSchema = yup.object({
    email: yup
      .string()
      .email("Please enter your email address")
      .required("Please enter your email address"),
    password: yup.string().required("Please enter your password"),
  });

  return (
    <Container className="d-flex justify-content-center align-items-center flex-column vh-100">
      <LogoStyle>
        <CardBody>
          <div className="justify-content-center d-flex mt-3 mb-5">
            <img
              src={`${process.env.PUBLIC_URL}/images/${Localization.logoFilename}`}
              alt={Localization.clientName}
              style={{ width: "70%" }}
            />
          </div>
        </CardBody>
      </LogoStyle>
      <CardStyle>
        <CardBody>
          <Formik
            innerRef={(instance) => {
              formikRef.current = instance;
            }}
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={onSubmit}
            validationSchema={loginSchema}
          >
            {(props: FormikProps<LoginFormValue>) => {
              const { touched, errors } = props;
              return (
                <Form autoComplete="off" className="mx-3">
                  <FormGroupStyle>
                    <LoginLabel>{"Registered Email"}</LoginLabel>
                    <div>
                      <FieldStyle name="email" type="text" />
                      {touched.email && errors.email && (
                        <div className="mt-2 text-danger">{errors.email}</div>
                      )}
                    </div>
                  </FormGroupStyle>
                  <FormGroupStyle>
                    <LoginLabel>{"Password"}</LoginLabel>
                    <div>
                      <FieldStyle name="password" type="password" />
                      {touched.password && errors.password && (
                        <div className="text-danger">{errors.password}</div>
                      )}
                    </div>
                  </FormGroupStyle>
                  <FormGroup className="mt-5 mb-4 d-flex justify-content-between">
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      className="w-100 h-50"
                      style={{
                        backgroundColor: Localization.tvsdPurple,
                        borderRadius: "10px",
                      }}
                    >
                      {isSubmitting ? "Submitting" : "Sign In"}
                    </Button>
                  </FormGroup>
                </Form>
              );
            }}
          </Formik>
        </CardBody>
      </CardStyle>
      <div style={{ height: "10%" }} />
      <Footer className="p-3 bg-white text-center">
        <img
          src={`${process.env.PUBLIC_URL}/images/${Localization.footer}`}
          alt="footer"
        />
      </Footer>
    </Container>
  );
};

const FormGroupStyle = styles(FormGroup)`
  text-align: left;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;
const LoginLabel = styles(Label)`
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 12px;
`;
const FieldStyle = styles(Field)`
  height: 55px;
  padding: 10px;
  width: 100%;
  border-width: thin;
  border
  margin-bottom: 5px;
  border-radius: 10px;
  outline: none !important;
  }
`;
const LogoStyle = styles(Card)`
  width: 450px;
  height:130px;
  background-color: inherit;
  box-shadow: 0px 0px 0px !important;
  @media (max-width: 414px) {
    max-width: 100%;
    height: calc(100vw / 3);
  }
`;
const CardStyle = styles(Card)`
  width: 400px;
  border: none;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 15px !important;
  @media (max-width: 414px) {
    max-width: 100%;
    background: #fbf9fe
  }
`;
const Container = styles.div`
  background-color: #f2f3ff;
`;
const Footer = styles.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  @media (max-width: 414px) {
    bottom: 0;
  }
`;

Login.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login })(Login);
