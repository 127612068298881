export const LOAD_USER = "LOAD_USER";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAIL = "LOAD_USER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const SEARCH_DUPLICATE = "SEARCH_DUPLICATE";
export const SEARCH_DUPLICATE_SUCCESS = "SEARCH_DUPLICATE_SUCCESS";
export const SEARCH_DUPLICATE_FAIL = "SEARCH_DUPLICATE_FAIL";
export const GET_RESULT_COUNT = "GET_RESULT_COUNT";
export const GET_RESULT_COUNT_SUCCESS = "GET_RESULT_COUNT_SUCCESS";
export const GET_RESULT_COUNT_FAIL = "GET_RESULT_COUNT_FAIL";
export const GET_RESULT_LIST = "GET_RESULT_LIST";
export const GET_RESULT_LIST_SUCCESS = "GET_RESULT_LIST_SUCCESS";
export const GET_RESULT_LIST_FAIL = "GET_RESULT_LIST_FAIL";
export const SUBMIT_FEEDBACK = "SUBMIT_FEEDBACK";
export const SUBMIT_FEEDBACK_SUCCESS = "SUBMIT_FEEDBACK_SUCCESS";
export const SUBMIT_FEEDBACK_FAIL = "SUBMIT_FEEDBACK_FAIL";
