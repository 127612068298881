import axios from "axios";
import { SEARCH_DUPLICATE, SEARCH_DUPLICATE_FAIL, SEARCH_DUPLICATE_SUCCESS } from "./types";
import { BaseURL } from "../constant";

export const searchDuplicate = (searchFields) => async (dispatch) => {
  dispatch({
    type: SEARCH_DUPLICATE,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(searchFields);

  try {
    const res = await axios.post(BaseURL + "/search-duplicate", body, config);

    const { user_input_info_id, last_searched_at, ...rest } = res.data;

    dispatch({
      type: SEARCH_DUPLICATE_SUCCESS,
      payload: {
        userInputInfoId: user_input_info_id,
        lastSearchedAt: last_searched_at,
        ...rest,
      },
    });
  } catch (err) {
    console.log(err);

    dispatch({
      type: SEARCH_DUPLICATE_FAIL,
      payload: {
        error: err?.response?.data?.message,
      },
    });
  }
};
