import { createContext } from "react";
import { MOBILE_COUNTRY_CODE } from "./countrycode";
import { Localization } from "constant/config";

const AppContext = createContext({
  submitted: false,
  setSubmitted: (submitted) => {
    console.log(submitted);
  },
  showOptionalField: false,
  setShowOptionalField: (showOptionalField) => {
    console.log(showOptionalField);
  },
  searchFields: {
    first_name: "",
    middle_name: "",
    last_name: "",
    country_code: MOBILE_COUNTRY_CODE.find(
      (el) => el.id === Localization.countryAbbrev
    ),
    phone_number: "",
    date_of_birth: null,
    city: "",
    zipcode: "",
    first_name_2: "",
    middle_name_2: "",
    last_name_2: "",
    alternate_country_code: MOBILE_COUNTRY_CODE.find(
      (el) => el.id === Localization.countryAbbrev
    ),
    alternate_phone_number: "",
    alternate_address: "",
    province: "",
    district: "",
    street: "",
    address: "",
    comaker_name: "",
    comaker_address: "",
  },
  setSearchFields: (searchFields) => {
    console.log(searchFields);
  },
  lastSearchedAt: "",
  setLastSearchedAt: (lastSearchedAt) => {
    console.log(lastSearchedAt);
  },
  searchCompleted: true,
  setSearchCompleted: (searchCompleted) => {
    console.log(searchCompleted);
  },
  resultCount: 0,
  setResultCount: (resultCount) => {
    console.log(resultCount);
  },
  resultList: [],
  setResultList: (resultList) => {
    console.log(resultList);
  },
});

export default AppContext;
